import _ from 'lodash';
import { useDropzone } from 'react-dropzone';
import { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SanDropzoneProps } from "models/types/san-elements/SanDropzoneProps";
import { enmDropzoneWorkType } from 'models/enums/DropzoneWorkType';
import { B2CApiEnums } from '@santsg/ui-component-core';
import { toast } from 'react-toastify';


export const SanDropZoneUploader = (props: SanDropzoneProps) => {
    const [t] = useTranslation();
    const [files, setFiles] = useState<File[]>([]);
    const [currentIndex, setCurrentIndex] = useState<number>();
    const inputRef = useRef<HTMLInputElement>(null);
    const [multiple, setMultiple] = useState<boolean>(props.multiple ? props.multiple : true);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [invalidName, setInvalidName] = useState<string>();
    const { getRootProps, getInputProps } = useDropzone({
        accept: props.accept ? props.accept : {
            'image/*': [],
            'application/pdf': [],
        },
        multiple: multiple,
        onDrop: (acceptedFiles: File[]) => {
            setInvalidName(undefined);
            validateFileName(acceptedFiles);
            if (files != null && files.length > 0 && currentIndex != null) {
                var clonedFiles = _.cloneDeep(files);
                var uploadedFile = acceptedFiles[0];
                var foundByName = _.find(files, function (c) { return c.name == uploadedFile.name });
                if (foundByName != null) {
                    setErrorMessage(`${t("sandropzone.sameNameError")} -> ${foundByName.name}`);
                    setTimeout(function () { setErrorMessage(undefined) }, 4000);
                    return
                }
                clonedFiles[currentIndex] = uploadedFile;
                setFiles(clonedFiles.map((file: File) => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })));
                setCurrentIndex(undefined);
                setMultiple(props.multiple ? props.multiple : true);
                props.onChange && clonedFiles && props.onChange(clonedFiles);
            } else {
                var found = false;
                var foundName = "";
                if (files.length != 0) {
                    _.each(acceptedFiles, function (a) {
                        var foundByName = _.find(files, function (c) { return c.name == a.name });
                        if (foundByName != null) {
                            found = true;
                            foundName = foundByName.name;
                        }
                    })
                    if (found) {
                        setErrorMessage(`${t("sandropzone.sameNameError")} -> ${foundName}`);
                        setTimeout(function () { setErrorMessage(undefined) }, 4000);
                        return;
                    }
                    var clonedFiles = _.cloneDeep(files);
                    clonedFiles = clonedFiles.concat(acceptedFiles);
                    setFiles(clonedFiles.map((file: File) => Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })));
                    props.onChange && clonedFiles.length > 0
                        ? props.onChange(clonedFiles)
                        : toast.error(`${t("common.alerts.typeIsNotSupported")}: ${JSON.stringify(props.accept)}`);

                } else {
                    setFiles(acceptedFiles.map((file: File) => Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })));

                    props.onChange && acceptedFiles.length > 0
                        ? props.onChange(acceptedFiles)
                        : toast.error(`${t("common.alerts.typeIsNotSupported")}: ${JSON.stringify(props.accept)}`);

                }
            }
        }
    });


    const validateFileName = (fileList: File[]) => {
        var foundInvalidNamedFile = _.find(fileList, function (f) { return f.name.includes(" ") });
        if (foundInvalidNamedFile != null)
            setInvalidName(foundInvalidNamedFile.name);
        else
            setInvalidName(undefined);
    }

    const handleThumbClick = (index: number) => {
        setCurrentIndex(index);
        setMultiple(false);
        inputRef && inputRef.current && inputRef.current.click();
    }

    const handlePrevThumbClick = () => {
        setCurrentIndex(0);
        inputRef && inputRef.current && inputRef.current.click();
    }

    const handleRemove = (name: string) => {
        var clonedFiles = _.cloneDeep(files);
        clonedFiles = _.filter(clonedFiles, function (c) { return c.name != name });
        validateFileName(clonedFiles);
        setFiles(clonedFiles.map((file: File) => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
    }

    const handleFsDialog = () => {
        inputRef.current?.click();
    }

    const handlePrevRemove = () => {
        setFiles([]);
        props.onChange && props.onChange();
    }

    const thumbs = files.map((file: any, index: number) => (
        <Fragment key={file.name}>
            <div style={{
                width: '100%',
                height: '150px',
                borderStyle: 'solid',
                alignItems: 'center',
                justifyContent: 'center',
                position: "relative"
            }}>
                <div style={{ display: 'flex', minWidth: 0, overflow: 'hidden' }} onClick={() => handleThumbClick(index)}>
                    <img
                        src={file.preview}
                        onLoad={() => { URL.revokeObjectURL(file.preview) }}
                        onError={i => i.currentTarget.style.display = 'none'}
                    />
                </div>
                <div className="san-dropzone-info-container text-center">
                    <div className="san-dropzone-remove">
                        <button className="btn btn-danger btn-sm" onClick={() => handleRemove(file.name)}>
                            {t("common.remove")}
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    ));

    const prevs = (
        <Fragment key={props.value?.imagePath}>
            <div style={{
                width: '100%',
                height: '150px',
                borderStyle: 'solid',
                alignItems: 'center',
                justifyContent: 'center',
                position: "relative"


            }}>
                <div onClick={() => handlePrevThumbClick()}>

                    <img
                        src={props.value?.imagePath}
                    />
                </div>
                <div className="san-dropzone-info-container">
                    <div className="san-dropzone-remove">
                        <button className="btn btn-danger btn-sm" onClick={() => handlePrevRemove()}>
                            {t("common.remove")}
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    );

    useEffect(() => {
        return () => files.forEach((file: any) => URL.revokeObjectURL(file.preview));
    }, []);

    const getVisibilityOfDropzone = () => {
        return (props.workType == enmDropzoneWorkType.Single && files.length == 0 && !props.value) || (props.workType != enmDropzoneWorkType.Single);
    }

    return (
        <section className="container san-dropzone-wrapper">
            <div className='dropzone-title'>{props.label}</div>
            {
                getVisibilityOfDropzone()
                &&
                <div {...getRootProps({ className: 'dropzone san-dropzone' })} onClick={handleFsDialog}>
                    <input {...getInputProps()} ref={inputRef} type='file' name="files" />
                    {
                        !errorMessage && !invalidName
                        &&
                        <p className="san-dropzone-label">{props.zoneInfo ?? t("sandropzone.dragAndDropText")}</p>
                    }
                    {
                        errorMessage
                        &&
                        <p className="san-dropzone-error-label">{errorMessage}</p>
                    }
                    {
                        invalidName
                        &&
                        <p className="san-dropzone-info-label">{`${t("sandropzone.invalidNameInfo")}`}</p>
                    }
                    <div className="san-dropzone-upload">
                        <button className="btn btn-warning btn-sm">
                            {t("common.upload")}
                        </button>
                    </div>

                </div>
            }

            {<aside>
                {props.value
                    ? prevs : thumbs
                }
            </aside>}


        </section>
    );
}