import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Main from 'components/main/Main';
import { allRoutes } from './RouteDefinitions';
import PublicRoutes from './PublicRoutes';

export function AppRoutes() {
  return <Routes>
    <Route path='/' element={<Main />}>
      {
        allRoutes.filter(w => w.permissions && w.permissions.length > 0).map((m, i) => {
          return <Route
            key={i}
            path={m.path}
            element={
              <React.Suspense fallback={<>Loading...</>}>
                <PrivateRoute>
                  <m.component params={m.params} />
                </PrivateRoute>

              </React.Suspense>
            }></Route>
        })
      }

    </Route>
    {
      PublicRoutes.map((route: RouteProps, i: number) => {
        return (
          <Route key={i} path={route.path} element={route.element} />
        )
      })
    }
  </Routes>
}