import _ from 'lodash';
import { useEffect, } from "react";
import { SanDropzoneProps } from "models/types/san-elements/SanDropzoneProps";
import { enmDropzoneWorkType } from 'models/enums/DropzoneWorkType';
import { SanDropZoneUploader } from './SanDropZoneUploader';
import { SanDropZoneSet } from './SanDropZoneSet';


export const SanDropZone = (props: SanDropzoneProps) => {
    useEffect(() => {

    }, []);

    return (
        <>
            {props.workType == enmDropzoneWorkType.Partial
                ?
                <SanDropZoneSet {...props} />
                :
                <SanDropZoneUploader {...props} />
            }
        </>
    );
}