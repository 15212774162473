import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import packageJSON from '../../../../package.json';
import { useAppSelector } from 'store/hooks';
import { ObjectValueController } from '@santsg/ui-common';
import { B2CApiServiceModels, B2CApiServices } from '@santsg/ui-component-core';
import { useDispatch } from 'react-redux';
import { setBuildNo } from 'store/features/ui/UiSlice';

const Footer = () => {
  const [t] = useTranslation();
  const currentYear = new Date().getFullYear();
  const buildNo = useAppSelector(state => state.ui?.build);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (ObjectValueController.isNullOrUndefinedOrEmpty(buildNo)) {
      getSiteInfo()
    }
  }, [])

  const getSiteInfo = async () => {
    const response = await B2CApiServices.SiteInfoService.GetSiteInfo(new B2CApiServiceModels.Module.SiteInfoService.SiteInfoRequest(), false, true, true)
    if (response != null) {
      dispatch(setBuildNo(response.body?.build))
    }
  }


  return (
    <div className='footer-area' >
      <footer className="main-footer">
        <strong>
          <span>Copyright © {currentYear} </span>
          <a href="https://santsg.com" target="_blank" rel="noopener noreferrer">
            SAN TSG
          </a>
        </strong>
        <span className='ms-2 me-2'>|</span>
        <div className="float-right d-none d-sm-inline-block">
          <b>{t('footer.version')}</b>
          <span>&nbsp;{buildNo}</span>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
