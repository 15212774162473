import { DateInput, DatePicker, MaskedInput } from "rsuite"
import _ from 'lodash';
import { useEffect, useState } from "react";
import moment from "moment";
import { SanDateInputProps } from "models/types/san-elements/SanDateInputProps";
import { ObjectValueController } from "@santsg/ui-common";

export const SanFormDatePicker = (props: SanDateInputProps) => {
    const [value, setValue] = useState<Date | null>();

    useEffect(() => {
        if( !ObjectValueController.isNullOrUndefinedOrEmpty(props.value)){
            var pValue = undefined;
            if (props.value) {
                if (moment(props.value).valueOf() > moment().add(-100, "years").valueOf())
                        pValue = props.value;
            }
            if (props.receiveFormat && pValue) {
                var convertedValue = moment(pValue, props.receiveFormat).toDate();
                setValue(convertedValue);
            }
            else if(pValue != undefined) {
                setValue(pValue);
            }
        }
    }, [props])

    //#region EVENTS
    const handleOnChange = (e: any) => {
        props.onChange && props.onChange(moment(e).utc(true).toDate(),props.id);
    }

    function isDateValid(dateStr: string) {
        const date = new Date(dateStr);
        return !isNaN(date.getTime());
    }

    const getValue = (): Date | undefined => {
        var val = moment(value).format(props.receiveFormat);
        if (value && isDateValid(val)) {
            return moment(val).toDate();
        }
        return undefined;
    }
    //#endregion

    const isBiggerThanToday = (date: Date) => {
        if(!props.minDate){
            return true;
        }
        const minDate = (props.minDate ? moment(props.minDate) : moment())
        return moment(date).valueOf() > minDate.valueOf();
    }

    const fnClean = (e:any) => {
        props.onChange && props.onChange(null,props.id);
        setValue(null);
    }

    return (
        <DatePicker
            onClean={fnClean}
            limitStartYear={100}
            limitEndYear={1}
            shouldDisableDate={(date: Date) => !isBiggerThanToday(date)}
            value={getValue()}
            placeholder={getValue() == undefined ? props.placeholder : moment(getValue()).format("DD.MM.yyyy")}
            disabled={props.readonly}
            format="dd.MM.yyyy"
            onSelect={handleOnChange}
        />
    )
}