/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  toggleMode,
  UiModes
} from 'store/features/ui/UiSlice';
import {
  NAVBAR_DARK_VARIANTS,
  NAVBAR_LIGHT_VARIANTS,
  SIDEBAR_DARK_SKINS,
  SIDEBAR_LIGHT_SKINS
} from 'utils/themes';
import useScrollPosition from 'hooks/useScrollPosition';
import { PfCheckbox, PfSelect } from '@profabric/react-components';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const ControlSidebar = () => {
  const dispatch = useAppDispatch();
  const mode = useAppSelector(state => state.ui?.mode);
  const headerBorder = useAppSelector(state => state.ui?.headerBorder);
  const headerFixed = useAppSelector(state => state.ui?.headerFixed);
  const footerFixed = useAppSelector(state => state.ui?.footerFixed);
  const navbarVariant = useAppSelector(state => state.ui?.navbarVariant);
  const sidebarSkin = useAppSelector(state => state.ui?.sidebarSkin);
  const layoutBoxed = useAppSelector(state => state.ui?.layoutBoxed);
  const layoutFixed = useAppSelector(state => state.ui?.layoutFixed);
  const menuItemFlat = useAppSelector(state => state.ui?.menuItemFlat);
  const menuChildIndent = useAppSelector(state => state.ui?.menuChildIndent);
  const menuSidebarCollapsed = useAppSelector(state => state.ui?.menuSidebarCollapsed);
  const scrollPosition = useScrollPosition();

  const handleModeChange = (mode: UiModes) => {
    dispatch(toggleMode(mode));
  };

  const getContainerPaddingTop = useCallback(() => {
    if (headerFixed) {
      return `${16 - (scrollPosition <= 16 ? scrollPosition : 0)}px`;
    }
    return `${73 - (scrollPosition <= 57 ? scrollPosition : 57)}px`;
  }, [scrollPosition, headerFixed]);

  return (
    <aside
      className={`control-sidebar control-sidebar-${mode == UiModes.DARK ? "dark" : "light"}`}
      style={{
        top: 0,
        bottom: footerFixed ? '57px' : '0px',
        padding: `${getContainerPaddingTop()} 16px 16px 16px`,
      }}
    >
      <h5>Customize</h5>
      <hr className="mb-2" />

      {/* <div style={{ padding: '8px 0' }}>
        <div className="mb-4">
          <input type='checkbox' className='form-control' checked={darkMode} onChange={handleDarkModeChange} />
          <span style={{ paddingLeft: "10px" }}>Dark mode</span>
        </div>
      </div> */}
      <legend className="col-form-label col-sm-2 pt-0">Mode</legend>
      <div className="col-sm-10">
        <div className="form-check">
          <input className="form-check-input" type="radio" onChange={() => handleModeChange(UiModes.DARK)} name="modes" id="radio-dark" value={"true"} checked={mode == UiModes.DARK} />
          <label className="form-check-label" htmlFor="gridRadios1">
            Dark
          </label>
        </div>
        <div className="form-check">
          <input className="form-check-input" type="radio" onChange={() => handleModeChange(UiModes.LIGHT)} name="modes" id="radio-light" value={"false"} checked={mode == UiModes.LIGHT} />
          <label className="form-check-label" htmlFor="gridRadios2">
            Light
          </label>
        </div>
      </div>
    </aside >
  );
};

export default ControlSidebar;
