import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toggleControlSidebar, toggleSidebarMenu, UiModes } from 'store/features/ui/UiSlice';
import MessagesDropdown from 'components/main/header/messages-dropdown/MessagesDropdown';
import NotificationsDropdown from 'components/main/header/notifications-dropdown/NotificationsDropdown';
import LanguagesDropdown from 'components/main/header/languages-dropdown/LanguagesDropdown';
import UserDropdown from 'components/main/header/user-dropdown/UserDropdown';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import WebSitesDropdown from './websites-dropdown/WebSitesDropdown';
import { Stack } from 'rsuite';
import SanModal from 'components/common/SanModal';
import { B2CUiEnums, B2CUiHelpers } from '@santsg/ui-component-core';
import { enmModalSize } from 'models/enums/ModalSize';
import { IMenuItem } from 'interfaces/IMenuItem';
import { enmMenuItemDisplayRestriction } from 'models/enums/MenuItemDisplayRestriction';
import { headerMenuItems } from 'utils/MenuDefinitions';
import { enmMenuItemWorkType } from 'models/enums/MenuItemWorkType';
import { ObjectValueController } from '@santsg/ui-common';
import { BaseFunctionProps } from 'models/types/other/BaseFunctionProps';
import _ from 'lodash';

const Header = () => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const navbarVariant = useAppSelector(state => state.ui?.navbarVariant);
  const headerBorder = useAppSelector(state => state.ui?.headerBorder);
  const authUi = useAppSelector(s => s.auth);
  const [openMenu, setOpenMenu] = useState<string>("");
  const [clickedMenu, setClickedMenu] = useState<IMenuItem>();
  const [open, setOpen] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const mode = useAppSelector(state => state.ui?.mode);
  const settings = useAppSelector(state => state.settings);

  useEffect(() => {
    if (!open)
      setOpenMenu("");
    return B2CUiHelpers.InputHelper.clickOutside(openMenu, open, setOpen);
  }, [open])

  const handleToggleMenuSidebar = () => {
    dispatch(toggleSidebarMenu());
  };

  const handleToggleControlSidebar = () => {
    dispatch(toggleControlSidebar());
  };

  const getContainerClasses = useCallback(() => {
    let classes = `main-header navbar navbar-expand ${navbarVariant}`;
    if (headerBorder) {
      classes = `${classes} border-bottom-0`;
    }
    return classes;
  }, [navbarVariant, headerBorder]);


  const handleModalTypeItemClick = (item: IMenuItem) => {
    if (openMenu != null && openMenu == item.path) {
      setOpenMenu("");
      setOpen(false);
      setClickedMenu(undefined);


    }
    else {
      setOpenMenu(item.path!);
      setOpen(true);
    }
  }

  const handleSubItemClick = (item: IMenuItem) => {
    setClickedMenu(item);
    setOpenMenu("");
    setOpen(false);
    if (item.workType == enmMenuItemWorkType.Modal)
      setShowModal(true);
    else
      navigate(item.path!);
  }

  const isOpen = (menuItem: IMenuItem) => {
    var pathSelector = menuItem.path;
    if (!ObjectValueController.isNullOrUndefinedOrEmpty(menuItem.parent))
      pathSelector = menuItem.parent;
    return openMenu == pathSelector && open;
  }

  const baseFunctionProps: BaseFunctionProps = {
    modalToggler: () => { setOpen(!open); setOpenMenu(""); }
  }


  const userHasAccess = (item: IMenuItem) => {
    var hasAccess = false;
    if (authUi?.authorizedModules && authUi.authorizedModules.length) {
      if (item.permission) {
        var modulePerm = _.find(authUi.authorizedModules, function (mod) { return mod.module == item.module?.toString() && mod.permissions.includes(item.permission!.toString()) });
        if (modulePerm)
          hasAccess = true;
      }
      else {
        if (item.children) {
          var anyChildrenHasAccess = false;
          _.each(item.children, function (i) {
            if (!i.children) {
              var modulePerm = _.find(authUi.authorizedModules, function (mod) { return mod.module == i.module?.toString() && mod.permissions.includes(i.permission!.toString()) });
              if (modulePerm)
                anyChildrenHasAccess = true;
            } else {
              _.each(i.children, function (f) {
                var modulePerm = _.find(authUi.authorizedModules, function (mod) { return mod.module == f.module?.toString() && mod.permissions.includes(f.permission!.toString()) });
                if (modulePerm)
                  anyChildrenHasAccess = true;
              })
            }

          });
          if (anyChildrenHasAccess)
            hasAccess = true;
        }
        else {
          return true;
        }
      }
    }
    return hasAccess;
  }

  const isVisible = (item: IMenuItem) => {
    var isBaseSite = authUi?.webSite?.isBaseSite;
    return userHasAccess(item) && ((isBaseSite && (item.displayRestriction == enmMenuItemDisplayRestriction.BaseSite == isBaseSite || item.displayRestriction == enmMenuItemDisplayRestriction.Common)) ||
      (!isBaseSite && item.displayRestriction != enmMenuItemDisplayRestriction.BaseSite));
  }


  return (
    <>
      <nav className={getContainerClasses()}>
        <ul className="navbar-nav">
          <li className="nav-item">
            <button
              onClick={handleToggleMenuSidebar}
              type="button"
              className="nav-link"
            >
              <i className="fas fa-bars" />
            </button>
          </li>
          {
            headerMenuItems.map((item: IMenuItem, index: number) => (
              isVisible(item)
              &&
              <li key={index} className='nav-item'>
                {
                  item.workType == enmMenuItemWorkType.Modal
                  &&
                  !item.children
                  &&
                  <a className='nav-link' onClick={() => handleSubItemClick(item)} >{t(`menu.` + item.label)}</a>
                }
                {
                  item.workType == enmMenuItemWorkType.Route
                  &&
                  !item.children
                  &&
                  <Link to={item.path!} className="nav-link">
                    {t("menu." + item.label)}
                  </Link>
                }
                {
                  item.children
                  &&
                  <>
                    <a onClick={() => handleModalTypeItemClick(item)} className='nav-link'>{t("menu." + item.label)}</a>
                    {
                      isOpen(item)
                      &&
                      <div className={`san-dd ${mode == UiModes.DARK ? 'mode-dark' : 'mode-light'}`} id={item.path}>
                        {
                          item.children.map((child: IMenuItem, childIndex: number) => (
                            <div key={childIndex} className='drop-menu-item'>
                              <a className='nav-link san-lk' onClick={() => handleSubItemClick(child)} >✧ {t(`menu.` + child.label)}</a>
                            </div>
                          ))
                        }
                      </div>
                    }
                  </>
                }
              </li>
            ))
          }
          {/* <SettingsDropdown modalToggler={modalToggler} /> */}

        </ul>
        <ul className="navbar-nav ml-auto">
          <WebSitesDropdown />
          <MessagesDropdown />
          <NotificationsDropdown />
          <LanguagesDropdown />
          <UserDropdown />
          {/* <li className="nav-item">
            <button
              type="button"
              className="nav-link"
              onClick={handleToggleControlSidebar}
            >
              <i className="fas fa-th-large" />
            </button>
          </li> */}
        </ul>
      </nav>
      <Stack>
        <SanModal
          backdrop={true}
          type={clickedMenu?.modalShowType ?? settings?.panel.modalShowType}
          open={showModal}
          size={clickedMenu?.size ? clickedMenu.size : enmModalSize.SM}
          onClose={() => setShowModal(false)}
          title={clickedMenu ? t("menu." + clickedMenu.label) : ""}
          body={clickedMenu ? clickedMenu.element : () => <></>}
          bodyProps={baseFunctionProps}
        />
      </Stack>
    </>

  );
};

export default Header;
