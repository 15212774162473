import CreatePassword from 'pages/create-password/CreatePassword';
import ForgotPassword from 'pages/forgot-password/ForgotPassword';
import Login from 'pages/login/Login';
import { RouteProps } from 'react-router-dom';

const PublicRoutes: RouteProps[] = [
  {
    path: "/login",
    element: <Login />
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />
  },
  {
    path: "/create-passcode",
    element: <CreatePassword />
  }
]

export default PublicRoutes;
