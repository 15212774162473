import _ from 'lodash';
import { useDropzone } from 'react-dropzone';
import { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SanDropzoneProps } from "models/types/san-elements/SanDropzoneProps";


export const SanDropZoneSet = (props: SanDropzoneProps) => {
    const [t] = useTranslation();
    const [files, setFiles] = useState<File[]>([]);
    const [currentIndex, setCurrentIndex] = useState<number>();
    const inputRef = useRef<HTMLInputElement>(null);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [invalidName, setInvalidName] = useState<string>();
    const { getRootProps, getInputProps } = useDropzone({
        accept: props.accept ? props.accept : {
            'image/*': [],
            'application/pdf': [],
        },
        multiple: false,
        onDrop: (acceptedFiles: File[]) => {
            setInvalidName(undefined);
            validateFileName(acceptedFiles);
            if (files != null && files.length > 0 && currentIndex != null) {
                var clonedFiles = _.cloneDeep(files);
                var uploadedFile = acceptedFiles[0];
                var foundByName = _.find(files, function (c) { return c.name == uploadedFile.name });
                if (foundByName != null) {
                    setErrorMessage(`${t("sandropzone.sameNameError")} -> ${foundByName.name}`);
                    setTimeout(function () { setErrorMessage(undefined) }, 4000);
                    return
                }
                clonedFiles[currentIndex] = uploadedFile;
                setFiles(clonedFiles.map((file: File) => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })));
                setCurrentIndex(undefined);
                props.onChange && props.onChange(clonedFiles, props.extraParam);
            } else {
                var found = false;
                var foundName = "";
                if (files.length != 0) {
                    _.each(acceptedFiles, function (a) {
                        var foundByName = _.find(files, function (c) { return c.name == a.name });
                        if (foundByName != null) {
                            found = true;
                            foundName = foundByName.name;
                        }
                    })
                    if (found) {
                        setErrorMessage(`${t("sandropzone.sameNameError")} -> ${foundName}`);
                        setTimeout(function () { setErrorMessage(undefined) }, 4000);
                        return;
                    }
                    var clonedFiles = _.cloneDeep(files);
                    clonedFiles = clonedFiles.concat(acceptedFiles);
                    setFiles(clonedFiles.map((file: File) => Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })));
                    props.onChange && props.onChange(clonedFiles, props.extraParam);
                } else {
                    setFiles(acceptedFiles.map((file: File) => Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })));
                    props.onChange && props.onChange(acceptedFiles, props.extraParam);
                }
            }
        }
    });

    const validateFileName = (fileList: File[]) => {
        var foundInvalidNamedFile = _.find(fileList, function (f) { return f.name.includes(" ") });
        if (foundInvalidNamedFile != null)
            setInvalidName(foundInvalidNamedFile.name);
        else
            setInvalidName(undefined);
    }

    const handleThumbClick = (index: number) => {
        setCurrentIndex(index);
        inputRef && inputRef.current && inputRef.current.click();
    }

    const handleRemove = (name: string) => {
        var clonedFiles = _.cloneDeep(files);
        clonedFiles = _.filter(clonedFiles, function (c) { return c.name != name });
        validateFileName(clonedFiles);
        setFiles(clonedFiles.map((file: File) => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
        props.onChange && props.onChange(null, props.extraParam);
    }

    const thumbs = files.map((file: any, index: number) => (
        <Fragment key={file.name}>
            <div style={{
                display: 'flex',
                borderRadius: 2,
                border: '1px solid #eaeaea',
                marginBottom: 8,
                marginRight: 8,
                width: 300,
                height: 200,
                padding: 4,
                boxSizing: 'border-box',
                cursor: "pointer",
                flexDirection: "column",
                gap: "4px"
            }}>
                <div className="san-dropzone-info-text text-center">
                    <span className="text-center">{file.name}</span>
                </div>
                <div style={{ display: 'flex', minWidth: 0, overflow: 'hidden', height: "100%" }} onClick={() => handleThumbClick(index)}>
                    <img
                        style={{ width: "100%", objectFit: "cover" }}
                        src={file.preview}
                        onLoad={() => { URL.revokeObjectURL(file.preview) }}
                    />
                </div>
                <div className="san-dropzone-info-container text-center">
                    <div className="san-dropzone-remove">
                        <button style={{ width: "100%" }} className="btn btn-danger btn-sm" onClick={() => handleRemove(file.name)}>
                            {t("common.remove")}
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    ));

    const valueThumbs = (
        <Fragment key={props.value?.name}>
            <div style={{
                display: 'flex',
                borderRadius: 2,
                border: '1px solid #eaeaea',
                marginBottom: 8,
                marginRight: 8,
                width: 300,
                height: 200,
                padding: 4,
                boxSizing: 'border-box',
                cursor: "pointer",
                flexDirection: "column",
                gap: "4px"
            }}>
                <div className="san-dropzone-info-text text-center">
                    <span className="text-center">{props.value?.name}</span>
                </div>
                <div style={{ display: 'flex', minWidth: 0, overflow: 'hidden' }} onClick={() => handleThumbClick(0)}>
                    <img
                        src={props.value?.imagePath}
                        style={{ objectFit: "cover" }}
                    // onLoad={() => { URL.revokeObjectURL(file.preview) }}
                    />
                </div>
                <div className="san-dropzone-info-container text-center">
                    <div className="san-dropzone-remove">
                        <button style={{ width: "100%" }} className="btn btn-danger btn-sm" onClick={() => handleRemove("ile.name")}>
                            {t("common.remove")}
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
    useEffect(() => {
    }, [files]);

    return (
        <section className="container san-dropzone-wrapper">
            <div {...getRootProps({ className: `dropzone san-dropzone-set ${props.value && (props.value.imagePath || props.value.file) ? "hidden" : ""}` })}>
                <input {...getInputProps()} ref={inputRef} type='file' name="files" />
                {
                    !errorMessage && !invalidName
                    &&
                    <p className="san-dropzone-label">{t("sandropzone.dragAndDropText")}</p>
                }
                {
                    errorMessage
                    &&
                    <p className="san-dropzone-error-label">{errorMessage}</p>
                }
                {
                    invalidName
                    &&
                    <p className="san-dropzone-info-label">{`${t("sandropzone.invalidNameInfo")}`}</p>
                }
            </div>
            {
                files.length > 0 &&
                <aside style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    marginTop: 16
                }}>
                    {thumbs}
                </aside>
            }
            {
                props.value
                &&
                props.value.imagePath
                &&
                <aside style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    marginTop: 16
                }}>
                    {valueThumbs}
                </aside>
            }

        </section>
    );
}