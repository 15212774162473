import { ChangeEvent, useEffect, useState } from "react";
import _ from 'lodash';
import { Form, Input, Toggle } from "rsuite";
import { nanoid } from "@reduxjs/toolkit";
import { AccepterType } from "models/enums/FormAccepterTypes";
import { SanFormSelect } from "./SanFormSelect";
import { B2CApiEnums, B2CUiModels, TVApiEnums, TVApiModels } from "@santsg/ui-component-core";
import { ObjectValueController } from "@santsg/ui-common";
import SanCheckboxDropdown from "./SanCheckboxDropdown";
import { SanPhoneInput } from "./SanPhoneInput";
import { SanTextAreaInput } from "./SanTextArea";
import { SanDateInput } from "./SanDateInput";
import { SanCheckboxInput } from "./SanCheckboxInput";
import { SanFormAutoComplete } from "./SanFormAutoComplete";
import { enmAutoCompleteWorkType } from "models/enums/AutoCompleteWorkType";
import { SanFormDatePicker } from "./SanFormDatePicker";
import { SanInputErrorLabel } from "./SanInputErrorLabel";
import { JsonEditor } from "json-edit-react";
import { SanNumberBoxInput } from "./SanNumberBox";
import { SanFormControlProps } from "models/types/san-elements/SanFormControlProps";
import { SanLabel } from "./SanLabel";
import { SanDynamicObject } from "models/types/san-elements/SanDynamicObject";
import { SanSwitchInput } from "./SanSwitch";

export const SanFormControl = (props: SanFormControlProps) => {
    const [hasError, setHasError] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>();
    const controlId = nanoid();

    useEffect(() => {
        if (props.errors && props.errors.length > 0) {
            var fieldError = _.find(props.errors, function (error) { return error.id == props.name; });
            if (fieldError != null) {
                setHasError(true);
                setErrorText(fieldError.message);
            } else {
                setHasError(false);
                setErrorText(undefined);
            }
        } else {
            setHasError(false);
            setErrorText(undefined);
        }
    }, [props]);


    //#region HELPERS
    function getObjectParamValue(obj: any, paramName: string): any {
        if (props.model) {
            if (props.value != null)
                return props.value;
            var value = obj[paramName];
            if (props.useCustomSelector && props.customSelectorKey && value != null) {
                if (typeof value == 'object') {
                    if (props.customSelectorKey.includes(".")) {
                        var split = props.customSelectorKey.split('.');
                        _.each(split, function (s) {
                            value = value[s];
                        })
                    }
                    else {
                        value = value[props.customSelectorKey];
                        return value;
                    }
                } else {
                    var pValue = JSON.parse(value);
                    return pValue[props.customSelectorKey];
                }
            }
            if (ObjectValueController.isNullOrUndefinedOrEmpty(value)) {
                return "";
            }
            else {
                if (props.autoCompleteWorkType != null) {
                    if (props.parseValue) {
                        value = JSON.parse(value);
                    }
                    var workType = props.autoCompleteWorkType;
                    if (props.customSelectorKey)
                        workType = enmAutoCompleteWorkType.Custom;
                    switch (props.autoCompleteWorkType) {
                        case enmAutoCompleteWorkType.Location:
                            var locationText = "";
                            if (value.hotel && value.hotel.name)
                                locationText += value.hotel.name + " / ";
                            if (value.village && value.village.name)
                                locationText += value.village.name + " / ";
                            if (value.town && value.town.name)
                                locationText += value.town.name + " / ";
                            if (value.city && value.city.name)
                                locationText += value.city.name + " / ";
                            if (value.country && value.country.name)
                                locationText += value.country.name;

                            if (locationText == "" && value.name)
                                locationText = value.name;
                            return locationText;
                        case enmAutoCompleteWorkType.Airport:
                            var aiportText = "";
                            if (value.airport && value.airport.name)
                                aiportText += value.airport.name;
                            console.log("airport'a girdi");
                            console.log(aiportText);
                            return aiportText;
                        case enmAutoCompleteWorkType.All:
                            var customText: string | undefined = "";
                            var parsedValue = value as TVApiModels.mdlAutoCompleteItem;
                            switch (parsedValue.type) {
                                case TVApiEnums.enmAutoCompleteResponseType.City:
                                    customText = parsedValue.city?.name + " / " + parsedValue.country?.name;
                                    break;
                                case TVApiEnums.enmAutoCompleteResponseType.Hotel:
                                    customText = parsedValue.hotel?.name + " / " + parsedValue.city?.name + " / " + parsedValue.country?.name;
                                    break;
                                case TVApiEnums.enmAutoCompleteResponseType.Airport:
                                    customText = parsedValue.airport?.name;
                                    break;
                            }
                            return customText;
                        case enmAutoCompleteWorkType.Custom:
                            if (props.customSelectorKey) {
                                return (value as SanDynamicObject)[props.customSelectorKey];
                            }
                            else {
                                return value;
                            }
                        case enmAutoCompleteWorkType.Excursion:
                            var excursionId = "";
                            if (value.id)
                                excursionId = value.id;
                            else if (value.excursion && value.excursion.name)
                                excursionId = value.excursion.id;
                            else if (value.town && value.town.name)
                                excursionId = value.town.id;
                            else if (value.city && value.city.name)
                                excursionId = value.city.id;
                            else if (value.category && value.category.name)
                                excursionId = value.category.id;
                            return excursionId;
                        case enmAutoCompleteWorkType.Tour:
                            var tourId = "";
                            if (value.tour && value.tour.name)
                                tourId = value.tour.id;
                            else if (value.city && value.city.name)
                                tourId = value.city.id;
                            else if (value.category && value.category.name)
                                tourId = value.category.id;
                            console.log(tourId);
                            return tourId;
                    }
                } else if (typeof value == 'object' && value.length > 0) {
                    return value.map(String);
                } else {
                    if (value == "False" || value == "True")
                        return value == "False" ? false : true
                    else
                        return value;
                }
            }
        }
        else
            return props.value;
    }
    //#endregion

    //#region EVENTS
    const handleFormSelectChange = (e: any) => {
        var value = null;
        var uidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        var isUid = uidRegex.test(e);
        if (isUid) {
            value = e;
        }
        else if (!isUid && !isNaN(parseInt(e))) {
            value = parseInt(e);
        }
        else if (typeof e === 'boolean')
            value = e;
        else
            value = e;
        return value;
    };

    const handleCheckboxDropdownChange = (values: string[]) => {
        props.onChange!({ ...props.model, [`${props.name}`]: values });
    };

    const handleInputChange = (value: string) => {
        if (props.model)
            props.onChange!({ ...props.model, [`${props.name}`]: value });
        else
            props.onChange && props.onChange(value);
    };

    const handleSwitchChange = (checked: boolean) => {
        if (props.model)
            props.onChange!({ ...props.model, [`${props.name}`]: checked });
        else
            props.onChange && props.onChange(checked);
    };

    const handleDropdownChange = (e: string) => {
        if (props.model) {
            props.onChange!({ ...props.model, [`${props.name}`]: handleFormSelectChange(e) })
        }
        else {
            return props.onChange!(JSON.parse(e));
        }
    };
    //#endregion

    //#region RENDERERS
    const renderAccepter = () => {
        switch (props.accepter) {
            case AccepterType.LABEL:
                return (
                    <div className="rs-form-control rs-form-control-wrapper">
                        <SanLabel text={props.text} htmlFor={props.htmlFor} helperText={props.helperText} />
                    </div>
                );
            case AccepterType.DROPDOWN:
                return (
                    <div className="rs-form-control rs-form-control-wrapper">
                        <SanFormSelect
                            readonly={props.readonly}
                            onSelect={handleDropdownChange}
                            activeKey={getObjectParamValue(props.model, props.name)}
                            dataSource={(props.dataSource as B2CUiModels.mdlIdNameCodeValue[])}
                            placeholder={props.placeholder} />
                        {
                            <SanInputErrorLabel name={props.name} controlId={controlId} hasError={hasError} errorText={errorText} />
                        }
                    </div>
                );
            case AccepterType.CHECKBOXPICKER:
                return (
                    <div className="rs-form-control rs-form-control-wrapper">
                        <SanCheckboxDropdown
                            readonly={props.readonly}
                            onChange={handleCheckboxDropdownChange}
                            activeItems={getObjectParamValue(props.model, props.name)}
                            dataSource={(props.dataSource as B2CUiModels.mdlIdNameCodeValue[])}
                            placeholder={props.placeholder} />
                        {
                            <SanInputErrorLabel name={props.name} controlId={controlId} hasError={hasError} errorText={errorText} />
                        }
                    </div>
                );
            case AccepterType.PHONEINPUT:
                return (
                    <div className="rs-form-control rs-form-control-wrapper">
                        <SanPhoneInput
                            readonly={props.readonly}
                            onChange={handleInputChange}
                            guide={props.guide}
                            placeholder={props.placeholder}
                            placeholderChar={props.placeholderChar}
                            keepCharPositions={props.keepCharPositions}
                            showMask={props.showMask}
                            value={getObjectParamValue(props.model, props.name)}
                        />
                        {
                            <SanInputErrorLabel name={props.name} controlId={controlId} hasError={hasError} errorText={errorText} />
                        }
                    </div>
                );
            case AccepterType.TEXTAREA:
                return (
                    <div className="rs-form-control rs-form-control-wrapper">
                        <SanTextAreaInput
                            readonly={props.readonly}
                            onChange={handleInputChange}
                            placeholder={props.placeholder}
                            rows={props.rows}
                            value={getObjectParamValue(props.model, props.name)}
                        />
                        {
                            <SanInputErrorLabel name={props.name} controlId={controlId} hasError={hasError} errorText={errorText} />
                        }
                    </div>
                );
            case AccepterType.DATEINPUT:
                return (
                    <div className="rs-form-control rs-form-control-wrapper">
                        <SanDateInput
                            readonly={props.readonly}
                            receiveFormat={props.receiveFormat}
                            onChange={handleInputChange}
                            placeholder={props.placeholder}
                            format={props.format}
                            value={getObjectParamValue(props.model, props.name)}
                        />
                        {
                            <SanInputErrorLabel name={props.name} controlId={controlId} hasError={hasError} errorText={errorText} />
                        }
                    </div>
                );
            case AccepterType.DATEPICKER:
                return (
                    <div className="rs-form-control rs-form-control-wrapper">
                        <SanFormDatePicker
                            readonly={props.readonly}
                            receiveFormat={props.receiveFormat}
                            onChange={handleInputChange}
                            placeholder={props.placeholder}
                            format={props.format}
                            minDate={props.minDate}
                            value={getObjectParamValue(props.model, props.name)}
                        />
                        {
                            <SanInputErrorLabel name={props.name} controlId={controlId} hasError={hasError} errorText={errorText} />
                        }
                    </div>
                );
            case AccepterType.CHECKBOX:
                return (
                    <div className="rs-form-control rs-form-control-wrapper">
                        <SanCheckboxInput

                            readonly={props.readonly}
                            onChange={handleInputChange}
                            value={getObjectParamValue(props.model, props.name)}
                            defaultChecked={props.defaultChecked}
                            placeHolder={props.placeholder}
                            label={props.label}
                            helperText={props.helperText}
                        />
                        {

                            <SanInputErrorLabel name={props.name} controlId={controlId} hasError={hasError} errorText={errorText} />
                        }
                    </div>
                );
            case AccepterType.SWITCH:
                return (
                    <div className="rs-form-control rs-form-control-wrapper">
                        <SanSwitchInput
                            helperText={props.helperText}
                            readonly={props.readonly}
                            onChange={handleSwitchChange}
                            value={getObjectParamValue(props.model, props.name)}
                            defaultChecked={props.defaultChecked}
                            placeHolder={props.placeholder}
                        />
                        {
                            <SanInputErrorLabel name={props.name} controlId={controlId} hasError={hasError} errorText={errorText} />
                        }
                    </div>
                )
            case AccepterType.AUTOCOMPLETE:
                return (
                    <div className="rs-form-control rs-form-control-wrapper">
                        <SanFormAutoComplete
                            onSelect={(result: B2CUiModels.mdlIdNameCodeValue) => props.onSelect && props.onSelect(result)}
                            onSearch={props.onSearch}
                            data={props.dataSource}
                            placeholder={props.placeholder}
                            value={getObjectParamValue(props.model, props.name)}
                        />
                        {
                            <SanInputErrorLabel name={props.name} controlId={controlId} hasError={hasError} errorText={errorText} />
                        }
                    </div>
                );
            case AccepterType.NUMBERBOX:
                return (
                    <div className="rs-form-control rs-form-control-wrapper">
                        <SanNumberBoxInput
                            style={props.style}
                            min={props.min}
                            max={props.max}
                            readonly={props.readonly}
                            onChange={handleInputChange}
                            placeholder={props.placeholder}
                            value={getObjectParamValue(props.model, props.name)}
                        />
                        {
                            <SanInputErrorLabel name={props.name} controlId={controlId} hasError={hasError} errorText={errorText} />
                        }
                    </div>
                );
            case AccepterType.JSONEDITOR:
                return (
                    <div className="rs-form-control rs-form-control-wrapper">
                        <JsonEditor
                            data={getObjectParamValue(props.model, props.name) ? JSON.parse(getObjectParamValue(props.model, props.name)) : [{}]}
                            onUpdate={({ newData }) => {
                                props.onUpdate && props.onUpdate(newData)
                            }} />
                        {
                            <SanInputErrorLabel name={props.name} controlId={controlId} hasError={hasError} errorText={errorText} />
                        }
                    </div>
                );
        }

    };
    //#endregion

    return (
        <>
            <div className={`rs-form-group ${hasError ? 'has-error' : ''} ${props.additionalClasses}`} role="group">
                {
                    props.label
                    && props.accepter != AccepterType.CHECKBOX &&
                    <>
                        <label id={`${props.name}-${controlId}-label`} className="rs-form-control-label" htmlFor={props.name}>{props.label}
                            {
                                props.helperText
                                &&
                                <Form.HelpText tooltip>{props.helperText}</Form.HelpText>
                            }
                        </label>

                    </>
                }
                {
                    props.type
                    &&
                    <div className="form-control-wrap">
                        <div className="rs-form-control rs-form-control-wrapper">

                            <input
                                placeholder={props.placeholder}
                                onChange={(e) => handleInputChange(e.currentTarget.value)} id={`${props.name}-${controlId}-input`} className="rs-input" type={props.type} aria-labelledby={props.label ? `${props.name}-${controlId}-label` : ""}
                                readOnly={props.readonly}
                                disabled={props.readonly}
                                aria-describedby={props.helperText ? `${props.name}-${controlId}-helpertext` : ""}
                                value={getObjectParamValue(props.model, props.name)}></input>
                            {
                                <SanInputErrorLabel name={props.name} controlId={controlId} hasError={hasError} errorText={errorText} />
                            }
                        </div>
                    </div>
                }
                {
                    props.accepter
                    &&
                    <div className="form-control-wrap">
                        {
                            renderAccepter()
                        }
                    </div>

                }

            </div>
        </>
    );
};