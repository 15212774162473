import { Input, InputNumber } from "rsuite"
import _ from 'lodash';
import { SanNumberBoxInputProps } from "models/types/san-elements/SanNumberBoxInputProps";
import { useEffect } from "react";

export const SanNumberBoxInput = (props: SanNumberBoxInputProps) => {

    useEffect(() => {
    }, [props])

    //#region EVENTS
    const handleOnChange = (value: string | number | null) => {
        props.onChange && props.onChange(value);
    }
    //#endregion

    return (
        <InputNumber
            style={props.style}
            disabled={props.readonly}
            value={props.value}
            placeholder={props.placeholder}
            onChange={handleOnChange}
            min={props.min ? props.min : 0}
            max={props.max ? props.max : 100}
        />
    )
}