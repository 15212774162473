import { Fragment, useEffect, useState } from "react";
import { Button, Drawer, Modal } from 'rsuite';
import _ from 'lodash';
import { B2CUiEnums } from "@santsg/ui-component-core";
import { SanModalProps } from "models/types/san-elements/SanModalProps";
import { enmModalSize } from "models/enums/ModalSize";

function SanModal(props: SanModalProps) {
    const [open, setOpen] = useState(props.open)

    useEffect(() => {
        setOpen(props.open)
    }, [props.open])

    const handleClose = () => {
        setOpen(false);
        props.onClose && props.onClose();
    };


    return (
        <Fragment>
            {
                props.type == B2CUiEnums.enmModalShowType.Drawer
                &&
                <Drawer keyboard={true} size={props.size ? props.size : enmModalSize.SM} backdrop={props.backdrop} open={open} onClose={handleClose}>
                    <Drawer.Header>
                        {
                            props.title
                            &&
                            <Drawer.Title>{props.title}</Drawer.Title>
                        }
                        {
                            (props.onClose || props.onConfirm)
                            &&
                            <Drawer.Actions>

                                {
                                    props.onConfirm && props.confirmLabel
                                    &&
                                    <Button onClick={() => props.onConfirm && props.onConfirm()} appearance="primary">
                                        {props.confirmLabel}
                                    </Button>
                                }


                            </Drawer.Actions>
                        }
                    </Drawer.Header>
                    <Drawer.Body>
                        {
                            props.body
                            &&
                            <props.body {...props.bodyProps} />
                        }
                    </Drawer.Body>
                </Drawer>
            }
            {
                props.type == B2CUiEnums.enmModalShowType.Modal
                &&
                <Modal overflow={false} backdrop={props.backdrop} keyboard={true} size={props.size ? props.size : enmModalSize.SM} open={open} onClose={handleClose}>
                    {
                        props.title
                        &&
                        <>
                            <Modal.Header closeButton={props.onClose ? true : false}>
                                <Modal.Title>{props.title}</Modal.Title>
                            </Modal.Header>
                            <hr />
                        </>
                    }
                    <Modal.Body className={props.additionalClass}>
                        {
                            props.body
                            &&
                            <props.body {...props.bodyProps} />
                        }
                    </Modal.Body>
                    {
                        (props.onClose || props.onConfirm)
                        &&
                        <Modal.Footer>
                            {
                                props.onConfirm
                                &&
                                <Button onClick={() => props.onConfirm && props.onConfirm()} appearance="primary">
                                    {props.confirmLabel}
                                </Button>
                            }
                        </Modal.Footer>
                    }
                </Modal>
            }
        </Fragment>
    )
}

export default SanModal;