import { B2CApiEnums, B2CApiServiceModels, B2CApiServices } from "@santsg/ui-component-core";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "store/hooks";
import _ from 'lodash'
import loadingGif from "../../assets/images/loading.gif";

const Overlay = () => {
    const uiSlice = useAppSelector((state) => state.ui);
    const [loadingImage, setLoadingImage] = useState<string>();

    useEffect(() => {
        getLoadingImage();
    }, []);

    const getLoadingImage = async () => {
        var request = new B2CApiServiceModels.Module.AppFile.AppFileGlobalDetailRequest();
        request.fileTypes = [B2CApiEnums.enmGlobalFileType.LOADING];
        var response = await B2CApiServices.AppFileService.GlobalDetail(request, false, true);
        if (response && response.header.success && response.body?.appFiles) {
            var firstImage = _.first(response.body.appFiles)?.appFile;
            if (firstImage && firstImage.imagePath) setLoadingImage(firstImage.imagePath);
        }
    }
    return (
        <>
            {uiSlice?.loading && (
                <div id="overlay">
                    <div className="spinner">
                        <img src={loadingImage ?? loadingGif} style={{ maxHeight: "150px", maxWidth: "150px", objectFit: "contain" }}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default Overlay;