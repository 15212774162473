/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useTranslation } from 'react-i18next';
import { B2CApiModels, B2CApiServiceModels, B2CApiServices, B2CUiEnums, B2CUiModels } from '@santsg/ui-component-core';
import _ from 'lodash';
import { enmCmsSettingsTab } from 'models/enums/CmsSettingsTab';

import { AccepterType } from 'models/enums/FormAccepterTypes';
import { ObjectValueController } from '@santsg/ui-common';
import { SanFormControl } from 'components/common/SanFormControl';
import { enmSettingOptionType } from 'models/enums/SettingOptionType';
import PanelSmtpSettings from './PanelSmtpSettings';
import { getSiteSettings } from 'store/features/settings/SettingsSlice';

const CmsSettings = () => {
    const dispatch = useAppDispatch();
    const mode = useAppSelector(state => state.ui?.mode);

    const [t] = useTranslation();
    const [activeTab, setActiveTab] = useState<enmCmsSettingsTab>(enmCmsSettingsTab.Settings);
    const [settingsResponse, setSettingsResponse] = useState<B2CApiServiceModels.Module.Settings.SettingListResponse>();
    const [settingsListRequest, setSettingsListRequest] = useState<B2CApiServiceModels.Module.Settings.SettingListRequest>({});
    const [openSettingForm, setOpenSettingForm] = useState<boolean>(false)
    const [setting, setSetting] = useState<B2CApiModels.Module.SettingModel.mdlSetting>()
    const settings = useAppSelector(state => state.settings);


    const authUi = useAppSelector(s => s.auth);

    useEffect(() => {
        if (!settingsResponse) {
            getCmsSettings();
        }
    }, [settingsResponse])

    const renderTabContent = () => {
        switch (activeTab) {
            case enmCmsSettingsTab.Settings:
                if (settingsResponse) {
                    return (
                        settingsResponse.data?.map((setting: B2CApiModels.Module.SettingModel.mdlSetting, index: number) => (
                            getSettingValue(setting)
                        ))
                    )
                } else {
                    return <></>
                }
            case enmCmsSettingsTab.Mail:
                return (<PanelSmtpSettings />)
        }
    }

    const checkDetailBySettingType = (type: string) => {
        const typeMap: Record<string, number> = {
            'System.Boolean': AccepterType.CHECKBOX,
            'System.Int32': AccepterType.NUMBERBOX,
            'System.DateTime': AccepterType.DATEINPUT,
            'System.String': AccepterType.TEXTAREA,
        };
        return typeMap[type?.split(',')[0]] || '';
    };



    const handleSettingsChangeV2 = async (pValue: string[] | string, item: B2CApiModels.Module.SettingModel.mdlSetting) => {
        const parsedValue = JSON.parse(item.value as string) as B2CApiModels.Module.SettingModel.mdlSettingValue;
        parsedValue.container?.forEach((f) => {
            if (f.code != null) {
                if (parsedValue.optionType === enmSettingOptionType.Select) {
                    f.selected = f.code === pValue;
                } else if (parsedValue.optionType === enmSettingOptionType.Check) {
                    pValue = pValue.toString().split(",");
                    f.value = Array.isArray(pValue) && pValue.includes(f.code);
                }
            }
        });

        const settingType = checkDetailBySettingType(item.valueType) as AccepterType;
        if (settingType == AccepterType.CHECKBOX)
            item.value = (item.value ? "True" : "False");
        item.code = `PanelSettings.${item.code}`;
        item.value = JSON.stringify(parsedValue);
        const response = await B2CApiServices.SettingService.Update(item, false, true);
        if (response.header && response.header.success)
            getCmsSettings();
    }

    const getSettingValue = (item: B2CApiModels.Module.SettingModel.mdlSetting) => {
        if (!item || !item.valueType) return null;
        const settingType = checkDetailBySettingType(item.valueType) as AccepterType;
        if (!ObjectValueController.isNullOrUndefinedOrEmpty(settingType)) {
            const parsedValue = JSON.parse(item.value as string) as B2CApiModels.Module.SettingModel.mdlSettingValue;
            const selectedValue = { value: parsedValue.container?.find((f) => f.selected)?.code };

            return (
                <SanFormControl
                    model={selectedValue}
                    onChange={(selected: typeof selectedValue) => handleSettingsChangeV2(selected.value!, item)}
                    name="value"
                    accepter={settingType}
                    label={t("siteSetting.value")}
                    placeholder={t("siteSetting.value")}
                    helperText={t("siteSetting.valueInputInfo")}
                />
            );
        }
        else if (item.keys) {
            const parsedValue = JSON.parse(item.value as string) as B2CApiModels.Module.SettingModel.mdlSettingValue;
            const options = item.keys.map(w => ({ name: w, id: w }));

            if ([enmSettingOptionType.Select, enmSettingOptionType.Option].includes(Number(parsedValue.optionType))) {
                const selectedValue = { value: parsedValue.container?.find((f) => f.selected)?.code };
                return (
                    <SanFormControl
                        additionalClasses='modal-form-control'
                        model={selectedValue}
                        onChange={(selected: typeof selectedValue) => handleSettingsChangeV2(selected.value!, item)}
                        name="value"
                        accepter={AccepterType.DROPDOWN}
                        dataSource={options}
                        label={t("siteSetting.panelSettings." + item.code)}
                        placeholder={t("siteSetting.value")}
                        helperText={t("siteSetting.panelSettings." + item.code + "InputInfo")}
                    />
                );
            } else if (parsedValue.optionType === enmSettingOptionType.Check) {
                const selectedValue = { value: parsedValue.container?.filter((contain) => contain.value).map((contain) => contain.code) };
                return (
                    <SanFormControl
                        additionalClasses='modal-form-control'
                        model={selectedValue}
                        onChange={(selected: typeof selectedValue) => handleSettingsChangeV2(selected.value!.toString(), item)}
                        name="value"
                        dataSource={options}
                        accepter={AccepterType.CHECKBOXPICKER}
                        label={t("siteSetting.value")}
                        placeholder={t("siteSetting.value")}
                        helperText={t("siteSetting.valueInputInfo")}
                    />
                );
            }
        }
        return null;
    };

    const getCmsSettings = async (req?: B2CApiServiceModels.Module.Settings.SettingListRequest) => {
        var request = req ? req : _.cloneDeep(settingsListRequest);
        request.groupCode = "PanelSettings";
        let response = await B2CApiServices.SettingService.List(request, false, true);
        if (response && response.body && response.body) {
            response.body.data?.map(sett => {
                sett.code = sett.code.split(".").splice(1).join(".");
            })
            dispatch(getSiteSettings())
            setSettingsResponse(response.body!)
        } else {
            setSettingsResponse(undefined)
        }
    }

    const handleTabChange = (type: enmCmsSettingsTab) => {
        switch (type) {
            case enmCmsSettingsTab.Settings:
                setActiveTab(enmCmsSettingsTab.Settings);
                if (!settingsResponse)
                    getCmsSettings();
                break;
            case enmCmsSettingsTab.Mail:
                setActiveTab(enmCmsSettingsTab.Mail);
                break;
        }
    }

    return (

        <div className='cms-settings-wrapper'>
            <ul className="nav nav-tabs">
                <li className="nav-tab-item">
                    <a className={`nav-link ${activeTab == enmCmsSettingsTab.Settings ? 'active' : ''}`} onClick={() => handleTabChange(enmCmsSettingsTab.Settings)}>
                        {t("cmsSettingsPage.panelSettings")}
                    </a>
                </li>
                <li className="nav-tab-item">
                    <a className={`nav-link ${activeTab == enmCmsSettingsTab.Mail ? 'active' : ''}`} onClick={() => handleTabChange(enmCmsSettingsTab.Mail)}>
                        {t("cmsSettingsPage.mailSettings")}
                    </a>
                </li>
            </ul>
            {
                renderTabContent()
            }
        </div>
    );
};

export default CmsSettings;
