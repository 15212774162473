import { B2CApiServiceModels } from "@santsg/ui-component-core";
import _ from 'lodash';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { LogFormProps } from "models/types/log/LogProps";

function LogDetail(props: LogFormProps): JSX.Element {
    const [t] = useTranslation();
    const [logResponse, setLog] = useState<B2CApiServiceModels.Module.Log.LogDetail>();
    useEffect(() => {
        setLog(props.detail);
    }, []);
    return (
        <div className="log-detail">
            <hr></hr>
            <div>
                <a target="_blank" href={logResponse?.log.portalUri}>{t("logPage.logDetail.portalUrlText")}</a>
            </div>
            <hr></hr>
            <div>
                <label>{t("logPage.logDetail.user")}</label>
                <p>{logResponse?.log.userExplanation}</p>
            </div>
            <hr></hr>
            <div>
                <label>{t("logPage.logDetail.title")}</label>
                <p>{logResponse?.log.title}</p>
            </div>
            <hr></hr>
            <div>
                <label>{t("logPage.logDetail.contentType")}</label>
                <p>{logResponse?.log.contentTypeExplanation}</p>
            </div>
            <hr></hr>
            <div>
                <label>{t("logPage.logDetail.type")}</label>
                <p>{logResponse?.log.typeExplanation}</p>
            </div>
            <hr></hr>
            <div>
                <label>{t("logPage.logDetail.operationTime")}</label>
                <p>{logResponse?.log.operationTime?.toString()}</p>
            </div>
            <hr></hr>
            <div>
                <label>{t("logPage.logDetail.detail")}</label>
                <p>{logResponse?.log.detail}</p>
            </div>
        </div>
    )
}
export { LogDetail };