import { Link } from 'react-router-dom';
import { PfImage } from '@profabric/react-components';
import styled from 'styled-components';
import { SidebarSearch } from 'components/sidebar-search/SidebarSearch';
import { useAppSelector } from 'store/hooks';
import MenuWrapper from 'components/menu-item/MenuWrapper';
import { useEffect, useState } from 'react';
import { B2CApiEnums, B2CApiServiceModels, B2CApiServices } from '@santsg/ui-component-core';
import _ from 'lodash';
import { sideBarItems } from 'utils/MenuDefinitions';
import { useDispatch } from 'react-redux';
import { toggleSidebarMenu } from 'store/features/ui/UiSlice';
import MenuWrapperV2 from 'components/menu-item/MenuWrapperV2';

const StyledBrandImage = styled(PfImage)`
 
`;

const StyledUserImage = styled(PfImage)`
  --pf-box-shadow: 0 3px 6px #00000029, 0 3px 6px #0000003b !important;
`;

const MenuSidebar = () => {
  const auth = useAppSelector(state => state.auth);
  const sidebarSkin = useAppSelector(state => state.ui?.sidebarSkin);
  const menuItemFlat = useAppSelector(state => state.ui?.menuItemFlat);
  const menuChildIndent = useAppSelector(state => state.ui?.menuChildIndent);
  const dispatch = useDispatch();
  const [logo, setLogo] = useState<string>("/img/logo.png");
  useEffect(() => {
    if (!auth?.webSite?.isBaseSite)
      getLogo();
  }, [])


  const getLogo = async () => {
    var request = new B2CApiServiceModels.Module.AppFile.AppFileGlobalDetailRequest();
    request.fileTypes = [B2CApiEnums.enmGlobalFileType.CMS_LOGO];
    var response = await B2CApiServices.AppFileService.GlobalDetail(request, false, true);
    if (response && response.header.success && response.body?.appFiles) {
      var firstImage = _.first(response.body.appFiles)?.appFile;
      if (firstImage && firstImage.imagePath) setLogo(firstImage.imagePath);
    }
  }

  const handleToggler = () => {
    var isCollapsed = document.querySelector("#root");
    if (isCollapsed?.classList.contains("sidebar-collapse"))
      dispatch(toggleSidebarMenu())
  }


  return (
    <aside onMouseOver={handleToggler} style={{ display: "flex", flexDirection: "column" }} className={`main-sidebar elevation-4 ${sidebarSkin}`}>
      <Link to="/" className={`brand-link ${logo != '/img/logo.png' ? 'brand-customized-link' : ''}`}>
        <StyledBrandImage
          src={logo}
          alt="SAN CMS"
          height={70}
          style={{ maxWidth: "240px", objectFit: "contain" }}
        />
      </Link>
      <div className="sidebar">
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="info text-center">
            {
              !auth?.webSite?.isBaseSite
              &&
              <span>{auth?.webSite?.domain.split("//")[1]}</span>
            }

            <Link to="/profile" className="d-block">
              {auth?.user?.userInfo?.firstName + ' ' + auth?.user?.userInfo?.lastName}
            </Link>
          </div>
        </div>

        <div className="form-inline">
          <SidebarSearch />
        </div>

        <nav>
          <ul
            className={`nav nav-pills nav-sidebar flex-column${menuItemFlat ? ' nav-flat' : ''
              }${menuChildIndent ? ' nav-child-indent' : ''}`}
            role="menu"
          >
            <MenuWrapperV2 items={sideBarItems} />
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default MenuSidebar;
