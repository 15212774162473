import { Dropdown, Form } from "rsuite"
import { B2CUiHelpers, B2CUiModels } from "@santsg/ui-component-core";
import _ from 'lodash';
import { Fragment, useEffect, useState } from "react";
import { SanFormSelectProps } from "models/types/san-elements/SanFormSelectProps";
import { nanoid } from "@reduxjs/toolkit";

export const SanFormSelect = (props: SanFormSelectProps) => {
    const [activeKey, setActiveKey] = useState<string>();
    useEffect(() => {
        if (typeof props.activeKey == "object")
            setActiveKey(JSON.stringify(props.activeKey));
        else
            setActiveKey(props.activeKey);

    }, [props])

    //#region HELPERS
    const getDropdownTitle = (activeKey?: string) => {
        var text = props.placeholder;
        if (activeKey != null) {
            var found = _.find(props.dataSource, function (type) { return type.id == activeKey });
            if (found && found.name) {
                text = found.name;
            }
        }
        return text!.replace(/([a-z])([A-Z])/g, '$1 $2');
    }
    //#endregion

    //#region EVENTS
    const handleChange = (activeKey?: string) => {
        var foundFromSource = _.find(props.dataSource, function (s) { return s.id == activeKey });
        if (foundFromSource && 'value' in foundFromSource && foundFromSource.value != undefined) {
            props.onSelect(foundFromSource.value as string);
        } else if (foundFromSource != null && foundFromSource.id != null) {
            props.onSelect(foundFromSource.id.toString())
        }
    }
    //#endregion

    return (
        <Dropdown disabled={props.readonly} activeKey={activeKey} onSelect={handleChange} className="san-form-dropdown" title={getDropdownTitle(activeKey)} trigger={"click"}>
            {
                props.dataSource && props.dataSource.map((data: B2CUiModels.mdlIdNameCodeValue, index: number) => (
                    <Fragment key={index}>
                        <Dropdown.Item active={activeKey == data.id} eventKey={data.id} style={{ width: "300px" }} >{data.name!.replace(/([a-z])([A-Z])/g, '$1 $2')}</Dropdown.Item>
                    </Fragment>
                ))
            }
        </Dropdown>
    )
}