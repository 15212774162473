import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { IMenuItem } from 'interfaces/IMenuItem';
import { useAppSelector } from 'store/hooks';
import { enmMenuItemDisplayRestriction } from 'models/enums/MenuItemDisplayRestriction';
import { Nav, Sidenav } from 'rsuite';

export interface MenuWrapperProps {
  items: IMenuItem[];
}

const MenuWrapperV2 = (props: MenuWrapperProps) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeItem, setActiveItem] = useState<string>();
  const [activeTreeMenus, setActiveTreeMenus] = useState<string[]>([]);
  const authUi = useAppSelector(s => s.auth);
  const ui = useAppSelector(s => s.ui);
  const [menuItems, setMenuItems] = useState<Array<IMenuItem>>();
  const activePath = useAppSelector(state => state.ui?.activePath);
  const collapseStatus = useAppSelector(x => x.ui?.menuSidebarCollapsed);


  useEffect(() => {
    if (!menuItems) {
      var items = new Array<IMenuItem>();
      if (authUi?.webSite?.isBaseSite) {
        items = _.filter(props.items, function (i) { return i.displayRestriction == enmMenuItemDisplayRestriction.BaseSite || i.displayRestriction == enmMenuItemDisplayRestriction.Common });
      } else {
        items = _.filter(props.items, function (i) { return i.displayRestriction != enmMenuItemDisplayRestriction.BaseSite });
      }
      setMenuItems(items);
    }
    if (location.pathname != "/") {
      var activeTreeMenus: string[] = [];
      var activeMenuPath = "";
      _.each(props.items, function (item) {
        if (location.pathname == item.path) {
          activeMenuPath = item.path;
          activeTreeMenus.push(item.path);
        } else {
          if (item.children) {
            _.each(item.children, function (subItem) {
              if (location.pathname == subItem.path!) {
                activeMenuPath = subItem.path;
                activeTreeMenus.push(subItem.path, item.path!);
              } else {
                if (subItem.children) {
                  _.each(subItem.children, function (c) {
                    if (location.pathname == c.path!) {
                      activeMenuPath = c.path;
                      activeTreeMenus.push(c.path, subItem.path!, item.path!);
                    }
                  })
                }
              }
            })
          }
        }

      });
      setActiveItem(activeMenuPath);
      setActiveTreeMenus(activeTreeMenus);
    } else {
      setActiveItem("/");
    }

    if (ui?.menuSidebarCollapsed) {
      setActiveItem(undefined);
      setActiveTreeMenus([]);
    }
  }, [props, activePath, authUi?.authorizedModules, ui?.menuSidebarCollapsed]);


  const handleNavigate = (path: string) => {
    setActiveItem(path);
    navigate(path);
  }

  const userHasAccess = (item: IMenuItem) => {
    var hasAccess = false;
    if (authUi?.authorizedModules && authUi.authorizedModules.length) {
      if (item.permission) {
        var modulePerm = _.find(authUi.authorizedModules, function (mod) { return mod.module == item.module?.toString() && mod.permissions.includes(item.permission!.toString()) });
        if (modulePerm)
          hasAccess = true;
      }
      else {
        if (item.children) {
          var anyChildrenHasAccess = false;
          _.each(item.children, function (i) {
            if (!i.children) {
              var modulePerm = _.find(authUi.authorizedModules, function (mod) { return mod.module == i.module?.toString() && mod.permissions.includes(i.permission!.toString()) });
              if (modulePerm)
                anyChildrenHasAccess = true;
            } else {
              _.each(i.children, function (f) {
                var modulePerm = _.find(authUi.authorizedModules, function (mod) { return mod.module == f.module?.toString() && mod.permissions.includes(f.permission!.toString()) });
                if (modulePerm)
                  anyChildrenHasAccess = true;
              })
            }

          });
          if (anyChildrenHasAccess)
            hasAccess = true;
        }
      }
    }
    return hasAccess;
  }

  const isVisible = (item: IMenuItem) => {
    var isBaseSite = authUi?.webSite?.isBaseSite;
    var result = userHasAccess(item) && ((isBaseSite && (item.displayRestriction == enmMenuItemDisplayRestriction.BaseSite == isBaseSite || item.displayRestriction == enmMenuItemDisplayRestriction.Common)) ||
      (!isBaseSite && item.displayRestriction != enmMenuItemDisplayRestriction.BaseSite));
    return result;
  }


  const setActiveKey = (e: any) => {
    console.log(e);
  }


  const renderTreeMenu = (menuItem: IMenuItem, index: number) => {
    return (
      <Fragment key={index}>
        {
          isVisible(menuItem)
          &&
          <Nav.Menu placement="rightStart" eventKey={menuItem.path} title={t(`menu.${menuItem.label}`)} icon={menuItem.iconV2 ? menuItem.iconV2 : undefined}>
            {
              menuItem.children?.map((childItem: IMenuItem, childIndex: number) => (
                isVisible(childItem)
                &&
                <Fragment key={childIndex}>
                  {
                    childItem.children && childItem.children.length > 0
                      ?
                      <Nav.Menu key={childIndex} placement="rightStart" eventKey={childItem.path} title={t(`menu.${childItem.label}`)} icon={childItem.iconV2 ? childItem.iconV2 : undefined}>
                        {
                          childItem.children.map((subItem: IMenuItem, subIndex: number) => (
                            isVisible(subItem)
                            &&
                            <Nav.Item onClick={() => handleNavigate(subItem.path!)} icon={subItem.iconV2 ? subItem.iconV2 : undefined} eventKey={subItem.path}>{t(`menu.${subItem.label}`)}</Nav.Item>
                          ))
                        }
                      </Nav.Menu>
                      :
                      <Nav.Item onClick={() => handleNavigate(childItem.path!)} icon={childItem.iconV2 ? childItem.iconV2 : undefined} eventKey={childItem.path}>{t(`menu.${childItem.label}`)}</Nav.Item>
                  }
                </Fragment>
              ))
            }
          </Nav.Menu>

        }
      </Fragment>
    )
  }


  return (
    <Fragment>
      <Sidenav expanded={!collapseStatus} defaultOpenKeys={['3', '4']}>
        <Sidenav.Body>
          {
            menuItems?.map((menuItem: IMenuItem, index: number) => (
              <Nav key={index} activeKey={menuItem.path} onSelect={setActiveKey}>
                {
                  menuItem.children && menuItem.children.length > 0
                    ?
                    renderTreeMenu(menuItem, index)
                    :
                    isVisible(menuItem)
                    &&
                    <Nav.Item onClick={() => handleNavigate(menuItem.path!)} key={index} eventKey={menuItem.path} icon={menuItem.iconV2 ? menuItem.iconV2 : undefined}>
                      {menuItem.label}
                    </Nav.Item>
                }
              </Nav>
            ))
          }

        </Sidenav.Body>
      </Sidenav>
    </Fragment>

  );
};

export default MenuWrapperV2;
