export enum Module {
    Page = '1',
    Component = '2',
    User = '3',
    SiteCulture = '4',
    Localization = '5',
    Layout = '6',
    LayoutDefinition = '7',
    Content = '8',
    ContentDefinition = '9',
    Setting = '10',
    CompositComponent = '11',
    WebSite = '12',
    SearchLink = '13',
    SiteUrl = '14',
    LocationParameter = '15',
    CustomForm = '16',
    ComponentSettings = '17',
    Event = '18',
    File = '19',
    Gallery = '20',
    Log = '21',
    Menu = '22',
    MetaSettings = '23',
    Notification = '24',
    SiteMember = '25',
    Task = '26',
    Receiver = '27',
    SmtpSettings = '28',
    Template = '29',
    Sender = '30',
    DataSource = '31',
    Rotator = '32',
    NotAuthorized = '99'

}
export enum Permission {
    Create = '1',
    Update = '2',
    Delete = '3',
    Read = '4'
}