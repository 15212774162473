import { IRouteV2 } from "interfaces/IRouteV2";
import { Module, Permission } from "models/enums/moduleAndPermission";
import React from "react";
import { B2CApiEnums, B2CApiModels, B2CUiHelpers, B2CUiModels } from "@santsg/ui-component-core";

//#region Settings Management
const SiteCultureList = React.lazy(() => import("modules/site-culture/SiteCultureList"));
const SiteUrlList = React.lazy(() => import("modules/site-url/SiteUrlList"));
const DataSourceListGroups = React.lazy(() => import("modules/datasource/DataSourceListGroupList"));
const DataSourceFormGroups = React.lazy(() => import("modules/datasource/DataSourceFormTemplateList"));
//#endregion

//#region User Management
const UserList = React.lazy(() => import("modules/user/user/UserList"));
const RoleList = React.lazy(() => import("modules/user/role/RoleList"));
const PolicyList = React.lazy(() => import("modules/user/role-policy/PolicyList"));
//#endregion

//#region Notification Management
const SmsSettingsList = React.lazy(() => import("modules/notification/SmsSettingsList"));
const SmtpSettingsList = React.lazy(() => import("modules/site-setting/SmtpSettingsList"));
const ContentList = React.lazy(() => import("modules/content/ContentDefinitionList"));
const NotificationSettingsList = React.lazy(() => import("modules/notification/NotificationSettingsList"));
const EventList = React.lazy(() => import("modules/event/EventList"));
const ReceiverList = React.lazy(() => import("modules/receiver/ReceiverList"));
//#endregion

//#region Site Management
const LocalizationList = React.lazy(() => import("modules/localization/LocalizationList"));
const MenuList = React.lazy(() => import("modules/menu/MenuList"));
const AppFileList = React.lazy(() => import("modules/app-file/AppFileList"));
const GalleryList = React.lazy(() => import("modules/gallery/GalleryList"));
const SiteSettingList = React.lazy(() => import("modules/site-setting/SiteSettingList"));
const MetaSettingList = React.lazy(() => import("modules/meta-setting/MetaSettingList"));
const ComponentList = React.lazy(() => import("modules/component/ComponentList"));
const CompositeComponentList = React.lazy(() => import("modules/compositecomponent/CompositComponentList"));
const StaticPageDefinitionList = React.lazy(() => import("modules/page/static/StaticPageDefinitionList"));
const DynamicPageDefinitionList = React.lazy(() => import("modules/page/dynamic/DynamicPageDefinitionList"));
const CustomPageDefinitionList = React.lazy(() => import("modules/page/custom/CustomPageList"));
const RotatorList = React.lazy(() => import("modules/rotator/RotatorList"));
const LocationParameterList = React.lazy(() => import("modules/location-parameters/LocationParameterList"));
const WebSiteList = React.lazy(() => import("modules/website/WebSiteList"));
const TemplateList = React.lazy(() => import("modules/template/TemplateList"));
const SearchLinkList = React.lazy(() => import("modules/search-link/SearchLinkList"));

//#endregion

//#region CRM Management
const DataSourceFormValueList = React.lazy(() => import("modules//datasource/DataSourceFormValueList"));
//#endregion


//#region 
const Dashboard = React.lazy(() => import("pages/Dashboard"));
const AppTaskList = React.lazy(() => import("modules/app-task/AppTaskList"));
const MailLogList = React.lazy(() => import("components/main/UnderConstruction"));
const SystemLogList = React.lazy(() => import("components/main/UnderConstruction"));
//#endregion



interface IRouteParam {
    name: string,
    value: string
}

const replaceUlrPattern = (path: string, prms: IRouteParam[]): string => {
    prms.forEach(p => {
        path = path.replaceAll(p.name, p.value);
    })
    return path;
}

export const allRoutes: Array<IRouteV2> = [
    //#region Settings Management
    {
        component: SiteCultureList,
        path: "/languages",
        permissions: [Permission.Read],
        module: Module.SiteCulture,
        getPath: () => "/languages",
        params: null,
    },
    {
        component: SiteUrlList,
        path: "/urls",
        permissions: [Permission.Read],
        module: Module.SiteUrl,
        getPath: () => "/urls",
        params: null,
    },
    {
        component: SiteSettingList,
        path: "/settings",
        permissions: [Permission.Read],
        module: Module.Setting,
        getPath: () => "/settings",
        params: null,
    },
    {
        component: DataSourceListGroups,
        path: "/list-groups",
        permissions: [Permission.Read],
        module: Module.DataSource,
        getPath: () => "/list-groups",
        params: null,
    },
    {
        component: DataSourceFormGroups,
        path: "/form-groups",
        permissions: [Permission.Read],
        module: Module.DataSource,
        getPath: () => "/form-groups",
        params: null,
    },
    //#endregion

    //#region User Management
    {
        component: UserList,
        path: "/users",
        permissions: [Permission.Read],
        module: Module.User,
        getPath: () => "/users",
        params: [{ code: "userType", value: B2CApiEnums.enmUserType.Panel }],
    },
    {
        component: PolicyList,
        path: "/policies",
        permissions: [Permission.Read],
        module: Module.User,
        getPath: () => "/policies",
        params: null,
    },
    {
        component: RoleList,
        path: "/roles",
        permissions: [Permission.Read],
        module: Module.User,
        getPath: () => "/roles",
        params: null,
    },
    //#endregion

    //#region Notification Management
    {
        component: SmsSettingsList,
        path: "/sms-settings",
        permissions: [Permission.Read],
        module: Module.Notification,
        getPath: () => "/sms-settings",
        params: null,
    },
    {
        component: SmtpSettingsList,
        path: "/smtp-settings",
        permissions: [Permission.Read],
        module: Module.Setting,
        getPath: () => "/smtp-settings",
        params: null,
    },
    {
        component: ContentList,
        path: "/contents",
        permissions: [Permission.Read],
        module: Module.ContentDefinition,
        getPath: () => "/contents",
        params: null,
    },
    {
        component: NotificationSettingsList,
        path: "/notification-settings",
        permissions: [Permission.Read],
        module: Module.Notification,
        getPath: () => "/notification-settings",
        params: null,
    },
    {
        component: EventList,
        path: "/events",
        permissions: [Permission.Read],
        module: Module.Event,
        getPath: () => "/events",
        params: null,
    },
    {
        component: ReceiverList,
        path: "/receivers",
        permissions: [Permission.Read],
        module: Module.Receiver,
        getPath: () => "/receivers",
        params: null,
    },
    //#endregion

    //#region Site Management
    {
        component: LocalizationList,
        path: "/translations",
        permissions: [Permission.Read],
        module: Module.Localization,
        getPath: () => "/translations",
        params: null,
    },
    {
        component: MenuList,
        path: "/menus",
        permissions: [Permission.Read],
        module: Module.Menu,
        getPath: () => "/menus",
        params: null,
    },
    {
        component: AppFileList,
        path: "/images",
        permissions: [Permission.Read],
        module: Module.File,
        getPath: () => "/images",
        params: [{code : "dataSourceListGroupType", value: B2CApiEnums.enmDataSourceListGroupType.IMAGECATEGORIES}],
    },
    {
        component: AppFileList,
        path: "/docs",
        permissions: [Permission.Read],
        module: Module.File,
        getPath: () => "/docs",
        params: [{code : "dataSourceListGroupType", value: B2CApiEnums.enmDataSourceListGroupType.DOCUMENTCATEGORIES}],
    },
    {
        component: GalleryList,
        path: "/galleries",
        permissions: [Permission.Read],
        module: Module.Gallery,
        getPath: () => "/galleries",
        params: null,
    },
    {
        component: TemplateList,
        path: "/templates",
        permissions: [Permission.Read],
        module: Module.Template,
        getPath: () => "/templates",
        params: null,
    },
    {
        component: ComponentList,
        path: "/component-settings",
        permissions: [Permission.Read],
        module: Module.Component,
        getPath: () => "/component-settings",
        params: null,
    },
    {
        component: CompositeComponentList,
        path: "/composit-components",
        permissions: [Permission.Read],
        module: Module.CompositComponent,
        getPath: () => "/composit-components",
        params: null,
    },
    {
        component: StaticPageDefinitionList,
        path: "/static-pages",
        permissions: [Permission.Read],
        module: Module.Page,
        getPath: () => "/static-pages",
        params: null,
    },
    {
        component: DynamicPageDefinitionList,
        path: "/dynamic-pages",
        permissions: [Permission.Read],
        module: Module.Page,
        getPath: () => "/dynamic-pages",
        params: null,
    },
    {
        component: CustomPageDefinitionList,
        path: "/product-pages",
        permissions: [Permission.Read],
        module: Module.Page,
        getPath: () => "/product-pages",
        params: null,
    },
    {
        component: RotatorList,
        path: "/rotators",
        permissions: [Permission.Read],
        module: Module.Rotator,
        getPath: () => "/rotators",
        params: null,
    },
    {
        component: LocationParameterList,
        path: "/location-parameters",
        permissions: [Permission.Read],
        module: Module.LocationParameter,
        getPath: () => "/location-parameters",
        params: null,
    },
    {
        component: AppTaskList,
        path: "/app-tasks",
        permissions: [Permission.Read],
        getPath: () => "/app-tasks",
    },
    {
        component: AppFileList,
        path: "/app-files",
        permissions: [Permission.Read],
        module: Module.File,
        getPath: () => "/app-files",
    },
    {
        component: GalleryList,
        path: "/galleries",
        permissions: [Permission.Read],
        module: Module.Gallery,
        getPath: () => "/galleries"
    },
    {
        component: SiteSettingList,
        path: "/site-settings",
        permissions: [Permission.Read],
        module: Module.Setting,
        getPath: () => "/site-settings",
        params: null,
    },
    {
        component: MetaSettingList,
        path: "/meta-settings",
        permissions: [Permission.Read],
        module: Module.MetaSettings,
        getPath: () => "/meta-settings"
    },
    {
        component: SearchLinkList,
        path: "/search-links",
        permissions: [Permission.Read],
        getPath: () => "/search-links",
        params: null,
    },
    {
        component: WebSiteList,
        path: "/web-sites",
        permissions: [Permission.Read],
        module: Module.WebSite,
        getPath: () => "/web-sites",
        params: null,
    },
    //#endregion

    //#region CRM Management
    {
        component: DataSourceFormValueList,
        path: "/newsletters",
        permissions: [Permission.Read],
        module: Module.DataSource,
        getPath: () => "/newsletters",
        params: [{ code: "title", value: "newsletterTitle" },{ code: "name", value: "Newsletter" },{ code: "tableFilters", name:"TEXT", value: "keyValueFilterPairs._mailAddress" },{code: "tableFilters", name:"TEXT", value: "keyValueFilterPairs.subscribeStatus" }],
    },
    {
        component: DataSourceFormValueList,
        path: "/contacts",
        permissions: [Permission.Read],
        module: Module.DataSource,
        getPath: () => "/contacts",
        params: [{ code: "title", value: "contactUsTitle" },{ code: "name", value: "Contact Us Form" },{ code: "tableFilters", name:"TEXT", value: "keyValueFilterPairs.mailAddress" },{ code: "tableFilters", name:"DATE", value: "keyValueFilterPairs.createDateBegin" },{ code: "tableFilters", name:"DATE", value: "keyValueFilterPairs.createDateEnd" }],
    },
    {
        component: UserList,
        path: "/site-members",
        permissions: [Permission.Read],
        module: Module.User,
        getPath: () => "/site-members",
        params: [{ code: "userType", value: B2CApiEnums.enmUserType.B2C }],
    },
    //#endregion

    //#region Header Menu 
    {
        component: Dashboard,
        permissions: [Permission.Read],
        module: Module.Page,
        path: "/",
        getPath: () => "/",
        params: null,
    },
    {
        component: AppTaskList,
        path: "/tasks",
        permissions: [Permission.Read],
        module: Module.Task,
        getPath: () => "/tasks",
        params: null,
    },
    {
        component: MailLogList,
        path: "/mail-logs",
        permissions: [Permission.Read],
        module: Module.Log,
        getPath: () => "/mail-logs",
        params: null,
    },
    {
        component: SystemLogList,
        path: "/system-logs",
        permissions: [Permission.Read],
        module: Module.Log,
        getPath: () => "/system-logs",
        params: null,
    }
    //#endregion
]