import React, { useState, useEffect, useCallback } from 'react';
import { Outlet, useNavigate, useNavigation } from 'react-router-dom';
import { toggleSidebarMenu, UiModes } from 'store/features/ui/UiSlice';
import { addBodyClass, addWindowClass, removeBodyClass, removeWindowClass } from 'utils/helpers';
import ControlSidebar from 'components/main/control-sidebar/ControlSidebar';
import Header from 'components/main/header/Header';
import MenuSidebar from 'components/main/menu-sidebar/MenuSidebar';
import Footer from 'components/main/footer/Footer';
import { PfImage } from '@profabric/react-components';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const Main = () => {
  const dispatch = useAppDispatch();
  const stateUi = useAppSelector(s => s.ui);
  const menuSidebarCollapsed = stateUi?.menuSidebarCollapsed;
  const controlSidebarCollapsed = stateUi?.controlSidebarCollapsed;
  const screenSize = stateUi?.screenSize;
  const auth = useAppSelector(state => state.auth);
  const [isAppLoaded, setIsAppLoaded] = useState(false);
  const uiInfo = useAppSelector(s => s.ui);


  const handleToggleMenuSidebar = () => {
    dispatch(toggleSidebarMenu());
  };

  useEffect(() => {
    setIsAppLoaded(Boolean(auth != null));
  }, [auth]);

  useEffect(() => {

    removeWindowClass('register-page');
    removeWindowClass('login-page');
    removeWindowClass('hold-transition');

    addWindowClass('sidebar-mini');

    if (uiInfo?.mode == UiModes.DARK)
      addWindowClass('dark-mode');

    // fetchProfile();
    return () => {
      removeWindowClass('sidebar-mini');
    };
  }, []);
  useEffect(() => {
    removeWindowClass('sidebar-closed');
    removeWindowClass('sidebar-collapse');
    removeWindowClass('sidebar-open');
    if (uiInfo?.mode == UiModes.DARK) {
      addBodyClass("rs-theme-dark");
      removeBodyClass("rs-theme-light");
    } else {
      addBodyClass("rs-theme-light");
      removeBodyClass("rs-theme-dark");
    }

    if (menuSidebarCollapsed && screenSize === 'lg') {
      addWindowClass('sidebar-collapse');
    } else if (menuSidebarCollapsed && screenSize === 'xs') {
      addWindowClass('sidebar-open');
    } else if (!menuSidebarCollapsed && screenSize !== 'lg') {
      addWindowClass('sidebar-closed');
      addWindowClass('sidebar-collapse');
    }
  }, [screenSize, menuSidebarCollapsed]);

  useEffect(() => {
    if (controlSidebarCollapsed) {
      removeWindowClass('control-sidebar-slide-open');
    } else {
      addWindowClass('control-sidebar-slide-open');
    }
  }, [screenSize, controlSidebarCollapsed]);

  const getAppTemplate = useCallback(() => {
    if (!isAppLoaded) {
      return (
        <div className="preloader flex-column justify-content-center align-items-center">
          <PfImage
            className="animation__shake"
            src="/img/logo.png"
            alt="AdminLTELogo"
            height={60}
            width={60}
          />
        </div>
      );
    }
    return (
      <>
        <Header />

        <MenuSidebar />
        <div className="content-wrapper">
          <section className="content">
            <Outlet />
          </section>
        </div>
        <Footer />
        <ControlSidebar />
        <div
          id="sidebar-overlay"
          role="presentation"
          onClick={handleToggleMenuSidebar}
          onKeyDown={() => { }}
        />
      </>
    );
  }, [isAppLoaded]);

  return <div className="wrapper">{getAppTemplate()}</div>;
};

export default Main;
