import { SanCheckboxDropdownProps } from "models/types/san-elements/SanCheckboxDropdownProps"
import { Fragment, useEffect, useState } from "react"
import _ from 'lodash';
import { B2CUiHelpers, B2CUiModels } from "@santsg/ui-component-core";
import { Form } from "rsuite";
import { nanoid } from "@reduxjs/toolkit";

const SanCheckboxDropdown = (props: SanCheckboxDropdownProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const [activeLabels, setActiveLabels] = useState<string[]>([]);
    useEffect(() => {
        if (props.activeItems && props.dataSource) {
            if (props.activeItems.length == 1) {
                var sArray = new Array<string>();
                _.each(props.activeItems, function (item) {
                    var foundSource = _.find(props.dataSource, function (source) { return source.id == item.toString() });
                    if (foundSource) {
                        sArray.push(foundSource.name!);
                    }
                })
                setActiveLabels(sArray);
            } else {
                var count = props.activeItems.length;
                setActiveLabels([`${count} items selected`]);
            }
        }
        return B2CUiHelpers.InputHelper.clickOutside(`panel-checkbox-drop-1`, open, setOpen);
    }, [props, open])

    //#region EVENTs
    const handleCheckboxChange = (item: B2CUiModels.mdlIdNameCodeValue) => {
        if (props.activeItems && props.activeItems.length > 0) {
            var cloned = _.cloneDeep(props.activeItems).map(String);
            if (cloned.includes(item.id as string)) {
                props.onChange && props.onChange(_.filter(cloned, function (c) { return c != item.id }));
            } else {
                cloned.push(item.id as string);
                props.onChange && props.onChange(cloned);
            }
        } else {
            var items = new Array<string>();
            items.push(item.id as string);
            props.onChange && props.onChange(items);
        }
    }
    //#endregion

    return (
        <div className="san-checkbox-picker" style={{ maxWidth: "300px" }} id={`panel-checkbox-drop-1`}>
            <input readOnly placeholder={props.placeholder} className="rs-input" type="text" onClick={() => setOpen(!open)} value={activeLabels.join(" | ")} />
            {
                open
                &&
                <div className={`rs-anim-fade rs-anim-in rs-picker-check-menu rs-picker-check-menu rs-picker-popup placement-bottom-start`}
                    style={{ minWidth: "300px" }}>
                    <div role="listbox" style={{ pointerEvents: `${props.readonly ? 'none' : 'all'}`, maxHeight: "320px" }} aria-multiselectable="true" className="rs-picker-check-menu rs-picker-check-menu-items">
                        {
                            props.dataSource && props.dataSource.map((item: B2CUiModels.mdlIdNameCodeValue, index: number) => (
                                <Fragment key={index}>
                                    <div role="option" className="rs-picker-list-item">
                                        <div className="rs-check-item rs-checkbox">
                                            <div className="rs-checkbox-checker">
                                                <label className="checkbox-dropdown-label">
                                                    <span className="rs-checkbox-control">
                                                        <input onChange={() => handleCheckboxChange(item)} role="checkbox" type="checkbox" checked={props.activeItems && props.activeItems.includes(item.id as string)} tabIndex={0} />
                                                    </span>
                                                    <span className="rs-checkbox-label"> {item.name} </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            ))
                        }
                    </div>
                </div>
            }

        </div>
    )
}

export default SanCheckboxDropdown;