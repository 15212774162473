import { Form, Toggle } from "rsuite"
import _ from 'lodash';
import { ChangeEvent, Fragment, useEffect } from "react";
import { SanSwitchProps } from "models/types/san-elements/SanSwitchProps";

export const SanSwitchInput = (props: SanSwitchProps) => {
    useEffect(() => {
    }, [props])

    //#region EVENTS
    const handleOnChange = (checked: boolean, event: ChangeEvent<HTMLInputElement>) => {
        props.onChange && props.onChange(checked);
    }
    //#endregion

    return (
        <Fragment>
            {
                <Toggle checked={props.value} value={props.value} disabled={props.readonly} onChange={handleOnChange}>
                    {props.placeHolder ?? ""}
                </Toggle>

            }
        </Fragment>

    )
}