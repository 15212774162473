import { B2CApiEnums, B2CUiEnums } from "@santsg/ui-component-core";
import CmsSettings from "components/main/header/settings-dropdown/CmsSettings";
import VisualSettings from "components/main/header/settings-dropdown/VisualSettings";
import { IMenuItem } from "interfaces/IMenuItem";
import { enmMenuItemDisplayRestriction } from "models/enums/MenuItemDisplayRestriction";
import { enmMenuItemWorkType } from "models/enums/MenuItemWorkType";
import { enmModalSize } from "models/enums/ModalSize";
import { LogList } from "modules/log/LogList";
import React from "react";
import GearCircleIcon from '@rsuite/icons/legacy/GearCircle';

export const sideBarItems: Array<IMenuItem> = [
    {
        path: '/settings-management',
        label: 'settingsManagement',
        iconV2: <i className="rs-sidenav-item-icon fas fa-cog" />,
        icon: 'fas fa-cog',
        children: [
            {
                path: '/languages',
                label: 'webSiteLanguages',
                icon: '',
                module: B2CApiEnums.enmModule.SiteCulture,
                permission: B2CApiEnums.enmPermission.Read,
            },
            {
                path: '/urls',
                label: 'webSiteUrls',
                icon: '',
                module: B2CApiEnums.enmModule.SiteUrl,
                permission: B2CApiEnums.enmPermission.Read,
            },
            {
                path: '/settings',
                label: 'generalSettings',
                icon: '',
                children: [
                    {
                        path: '/settings?groupCode=general',
                        label: 'siteParameters',
                        icon: '',
                        module: B2CApiEnums.enmModule.Setting,
                        permission: B2CApiEnums.enmPermission.Read,
                    },
                    {
                        path: '/settings?groupCode=thirdparty',
                        label: 'thirdPartyScripts',
                        icon: '',
                        module: B2CApiEnums.enmModule.Setting,
                        permission: B2CApiEnums.enmPermission.Read,
                    },
                ]
            },
            {
                path: '/data-source',
                label: 'dataSources',
                icon: '',
                children: [
                    {
                        path: '/list-groups',
                        label: 'dataSourceListGroups',
                        icon: '',
                        module: B2CApiEnums.enmModule.DataSource,
                        permission: B2CApiEnums.enmPermission.Read,
                    },
                    {
                        path: '/form-groups',
                        label: 'dataSourceFormGroups',
                        icon: '',
                        module: B2CApiEnums.enmModule.DataSource,
                        permission: B2CApiEnums.enmPermission.Read,
                    },
                ]
            }
        ]
    },
    {
        path: '/user-management',
        label: 'userManagement',
        iconV2: <i className="rs-sidenav-item-icon fas fa-user" />,
        icon: 'fas fa-user',
        children: [
            {
                path: '/users',
                label: 'users',
                icon: '',
                displayRestriction: enmMenuItemDisplayRestriction.Common,
                module: B2CApiEnums.enmModule.User,
                permission: B2CApiEnums.enmPermission.Read,
            },
            {
                path: '/roles',
                label: 'roles',
                icon: '',
                displayRestriction: enmMenuItemDisplayRestriction.Common,
                module: B2CApiEnums.enmModule.User,
                permission: B2CApiEnums.enmPermission.Read,
            },
            {
                path: '/policies',
                label: 'policies',
                icon: '',
                module: B2CApiEnums.enmModule.User,
                permission: B2CApiEnums.enmPermission.Read,
            }
        ]
    },
    {
        path: '/notification-management',
        label: 'notificationManagement',
        iconV2: <i className="rs-sidenav-item-icon fas fa-bell" />,
        icon: 'fas fa-bell',
        children: [
            {
                path: '/sms-settings',
                label: 'smsSettings',
                icon: '',
                module: B2CApiEnums.enmModule.Setting,
                permission: B2CApiEnums.enmPermission.Read,
            },
            {
                path: '/mail-settings',
                label: 'mailSettings',
                icon: '',
                children: [
                    {
                        path: '/smtp-settings',
                        label: 'smtpSettings',
                        icon: '',
                        module: B2CApiEnums.enmModule.SmtpSettings,
                        permission: B2CApiEnums.enmPermission.Read,
                    },
                    {
                        path: '/contents?type=' + B2CUiEnums.enmContentDefinitionType.MailContent,
                        label: 'mailTemplates',
                        icon: '',
                        module: B2CApiEnums.enmModule.ContentDefinition,
                        permission: B2CApiEnums.enmPermission.Read,
                    }
                ]
            },
            {
                path: '/notification-settings',
                label: 'notificationSettings',
                icon: '',
                module: B2CApiEnums.enmModule.Notification,
                permission: B2CApiEnums.enmPermission.Read,
            },
            {
                path: '/receivers',
                label: 'receivers',
                icon: '',
                module: B2CApiEnums.enmModule.Receiver,
                permission: B2CApiEnums.enmPermission.Read,
            },
            {
                path: '/events',
                label: 'events',
                icon: '',
                module: B2CApiEnums.enmModule.Event,
                permission: B2CApiEnums.enmPermission.Read,
            },
        ]
    },
    {
        displayRestriction: enmMenuItemDisplayRestriction.Common,
        path: '/website-management',
        label: 'siteManagement',
        iconV2: <i className="rs-sidenav-item-icon fas fa-globe" />,
        icon: 'fas fa-globe',
        children: [
            {
                path: '/settings?groupCode=partner',
                label: 'siteSettings',
                icon: '',
                module: B2CApiEnums.enmModule.Setting,
                permission: B2CApiEnums.enmPermission.Read,
            },
            {
                path: '/translations',
                label: 'translation',
                icon: '',
                module: B2CApiEnums.enmModule.Localization,
                permission: B2CApiEnums.enmPermission.Read,
            },
            {
                path: '/menus',
                label: 'menus',
                icon: '',
                module: B2CApiEnums.enmModule.Menu,
                permission: B2CApiEnums.enmPermission.Read,
            },
            {
                path: '/file-management',
                label: 'fileManagement',
                icon: '',
                children: [
                    {
                        path: '/images',
                        label: 'images',
                        icon: '',
                        module: B2CApiEnums.enmModule.File,
                        permission: B2CApiEnums.enmPermission.Read,
                    },
                    {
                        path: '/docs',
                        label: 'documents',
                        icon: '',
                        module: B2CApiEnums.enmModule.File,
                        permission: B2CApiEnums.enmPermission.Read,
                    }
                ]
            },
            {
                path: '/galleries',
                label: 'galleries',
                icon: '',
                module: B2CApiEnums.enmModule.Gallery,
                permission: B2CApiEnums.enmPermission.Read,
            },
            {
                path: '/contents?type=' + B2CUiEnums.enmContentDefinitionType.HtmlContent,
                label: 'contents',
                icon: '',
                module: B2CApiEnums.enmModule.ContentDefinition,
                permission: B2CApiEnums.enmPermission.Read,
            },
            {
                path: '/templates',
                label: 'templates',
                icon: '',
                module: B2CApiEnums.enmModule.Template,
                permission: B2CApiEnums.enmPermission.Read,
            },
            {
                path: '/component-management',
                label: 'componentManagement',
                icon: '',
                children: [
                    {
                        path: '/component-settings',
                        label: 'componentSettings',
                        icon: '',
                        module: B2CApiEnums.enmModule.ComponentSettings,
                        permission: B2CApiEnums.enmPermission.Read,
                    },
                    {
                        path: '/composit-components',
                        label: 'compositComponents',
                        icon: '',
                        module: B2CApiEnums.enmModule.CompositComponent,
                        permission: B2CApiEnums.enmPermission.Read,
                    }
                ]
            },
            {
                path: '/website-layout',
                label: 'webSiteLayouts',
                icon: '',
                children: [
                    {
                        path: '/static-pages',
                        label: 'staticPages',
                        icon: '',
                        module: B2CApiEnums.enmModule.Page,
                        permission: B2CApiEnums.enmPermission.Read,
                    },
                    {
                        path: '/dynamic-pages',
                        label: 'dynamicPages',
                        icon: '',
                        module: B2CApiEnums.enmModule.Page,
                        permission: B2CApiEnums.enmPermission.Read,
                    },
                    {
                        path: '/product-pages',
                        label: 'productPages',
                        icon: '',
                        module: B2CApiEnums.enmModule.Page,
                        permission: B2CApiEnums.enmPermission.Read,
                    },
                    {
                        path: '/rotators',
                        label: 'rotators',
                        icon: '',
                        module: B2CApiEnums.enmModule.Rotator,
                        permission: B2CApiEnums.enmPermission.Read,
                    },
                    {
                        path: '/location-parameters',
                        label: 'locationParameters',
                        icon: '',
                        module: B2CApiEnums.enmModule.LocationParameter,
                        permission: B2CApiEnums.enmPermission.Read,
                    },
                    {
                        path: '/search-links',
                        label: 'searchLinks',
                        icon: '',
                        module: B2CApiEnums.enmModule.SearchLink,
                        permission: B2CApiEnums.enmPermission.Read,
                    }
                ]
            },
            {
                path: '/meta-settings',
                label: 'metaSettings',
                icon: '',
                module: B2CApiEnums.enmModule.MetaSettings,
                permission: B2CApiEnums.enmPermission.Read,
            },
            {
                displayRestriction: enmMenuItemDisplayRestriction.BaseSite,
                path: '/web-sites',
                label: 'webSites',
                icon: '',
                module: B2CApiEnums.enmModule.WebSite,
                permission: B2CApiEnums.enmPermission.Read,
            },
        ]
    },
    {
        path: '/crm-management',
        label: 'crmManagement',
        iconV2: <i className="rs-sidenav-item-icon fas fa-address-card" />,
        children: [
            {
                path: '/contacts',
                label: 'contactList',
                icon: '',
                module: B2CApiEnums.enmModule.DataSource,
                permission: B2CApiEnums.enmPermission.Read,
            },
            {
                path: '/newsletters',
                label: 'newsletterList',
                icon: '',
                module: B2CApiEnums.enmModule.DataSource,
                permission: B2CApiEnums.enmPermission.Read,
            },
            {
                path: '/site-members',
                label: 'siteMembers',
                icon: '',
                module: B2CApiEnums.enmModule.User,
                permission: B2CApiEnums.enmPermission.Read,
            }
        ]
    }
];


export const headerMenuItems: Array<IMenuItem> = [
    {
        path: '/',
        label: "dashboard",
        icon: '',
        children: null,
        displayRestriction: enmMenuItemDisplayRestriction.Common,
        workType: enmMenuItemWorkType.Route
    },
    {
        path: '/settings',
        label: 'settings',
        icon: '',
        displayRestriction: enmMenuItemDisplayRestriction.SubSites,
        module: B2CApiEnums.enmModule.MasterSettings,
        permission: B2CApiEnums.enmPermission.Read,
        workType: enmMenuItemWorkType.Modal,
        children: [
            {
                parent: '/settings',
                path: '/panel-settings',
                label: 'panelSettings',
                icon: '',
                workType: enmMenuItemWorkType.Modal,
                size: enmModalSize.SM,
                element: CmsSettings,
            },
            {
                parent: '/settings',
                path: '/visual-settings',
                label: 'visualSettings',
                icon: '',
                workType: enmMenuItemWorkType.Modal,
                element: VisualSettings,
            }
        ]
    },
    {
        path: '/tasks',
        label: 'tasks',
        icon: '',
        workType: enmMenuItemWorkType.Route,
        module: B2CApiEnums.enmModule.Task,
        permission: B2CApiEnums.enmPermission.Read,
        displayRestriction: enmMenuItemDisplayRestriction.SubSites,
    },
    {
        path: '/logs',
        label: 'logs',
        icon: '',
        workType: enmMenuItemWorkType.Route,
        module: B2CApiEnums.enmModule.Log,
        permission: B2CApiEnums.enmPermission.Read,
        displayRestriction: enmMenuItemDisplayRestriction.SubSites,
        children: [
            {
                path: '/mail-logs',
                label: 'mailLogs',
                icon: '',
                workType: enmMenuItemWorkType.Modal,
                modalShowType: B2CUiEnums.enmModalShowType.Drawer,
                element: () => LogList({ contentType: B2CApiEnums.enmLogContentType.Mail }),
                size: enmModalSize.FULL
            },
            {
                path: '/system-logs',
                label: 'systemLogs',
                icon: '',
                workType: enmMenuItemWorkType.Modal,
                modalShowType: B2CUiEnums.enmModalShowType.Drawer,
                element: () => LogList({}),
                size: enmModalSize.FULL
            }
        ]
    },
];
