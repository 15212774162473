import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { addBodyClass, removeBodyClass, setWindowClass } from 'utils/helpers';
import { PfCheckbox, PfButton } from '@profabric/react-components';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { B2CApiEnums, B2CApiServiceModels, B2CApiServices } from '@santsg/ui-component-core';
import { removeSession, setUser } from 'store/features/user/UserSlice';
import { Form, Schema } from 'rsuite';
import { mailRule, requireStringRule } from 'utils/globalRules';
import { getSiteCulture } from 'store/features/siteCulture/SiteCultureSlice';
import _ from 'lodash';
import { setCompanyLogo, setLoginBackground, toLoading, toUnloading, UiModes } from 'store/features/ui/UiSlice';
import { CookieManager, ObjectValueController } from '@santsg/ui-common';
import Footer from 'components/main/footer/Footer';

const Login = () => {
  const appDispatch = useAppDispatch();
  const dispatch = useAppDispatch();

  const uiInfo = useAppSelector(s => s.ui);
  const [t] = useTranslation();

  const [rememberMe, setRememberMe] = useState(false);
  const [loginRequest, setLoginRequest] = useState<B2CApiServiceModels.Module.User.UserLoginRequest>({});
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    var remember = CookieManager.get("rememberMe");
    if (!ObjectValueController.isNullOrUndefinedOrEmpty(remember)) {
      setRememberMe(true);
      setLoginRequest(JSON.parse(remember));
    } else {
      setRememberMe(false);
    }
    dispatch(toLoading());
    dispatch(removeSession());
    if (uiInfo?.mode == UiModes.DARK) {
      addBodyClass("rs-theme-dark");
      removeBodyClass("rs-theme-light");
    } else {
      addBodyClass("rs-theme-light");
      removeBodyClass("rs-theme-dark");
    }

    setWindowClass(`hold-transition login-page ${uiInfo?.mode == UiModes.DARK ? "mode-dark" : "mode-light"}`);

    if (!uiInfo?.loginBackground || !uiInfo.companyLogo)
      getGlobalStyles();
    else
      dispatch(toUnloading());
  }, [])

  const getGlobalStyles = async () => {
    var request = new B2CApiServiceModels.Module.AppFile.AppFileGlobalDetailRequest();
    request.fileTypes = [B2CApiEnums.enmGlobalFileType.COMPANY_LOGO, B2CApiEnums.enmGlobalFileType.LOGIN_BACKGROUND];
    var response = await B2CApiServices.AppFileService.GlobalDetail(request, false, true);
    if (response && response.header.success && response.body?.appFiles) {
      var logo = _.find(response.body.appFiles, function (f) { return f.fileType == B2CApiEnums.enmGlobalFileType.COMPANY_LOGO });
      var loginImage = _.find(response.body.appFiles, function (f) { return f.fileType == B2CApiEnums.enmGlobalFileType.LOGIN_BACKGROUND });
      if (logo && logo.appFile && logo.appFile.imagePath) {
        dispatch(setCompanyLogo(logo.appFile.imagePath));
      }

      if (loginImage && loginImage.appFile && loginImage.appFile.imagePath) {
        dispatch(setLoginBackground(loginImage.appFile.imagePath));
      }
    }
    dispatch(toUnloading());
  }

  const handlePassChange = () => {
    setVisible(!visible);
  };

  const handleSubmit = async () => {
    if (ObjectValueController.isNullOrUndefinedOrEmpty(loginRequest.mail)) {
      toast.error(t("login.mailCanNotBeEmpty"));
      return;
    }
    if (ObjectValueController.isNullOrUndefinedOrEmpty(loginRequest.password)) {
      toast.error(t("login.passwordCanNotBeEmpty"));
      return;
    }
    dispatch(toLoading());
    try {
      rememberMe ? CookieManager.set("rememberMe", JSON.stringify(loginRequest)) : CookieManager.remove("rememberMe");
      let res = await B2CApiServices.UserService.AuthService.Login(loginRequest);
      if (res.header.success) {
        var user = res.body?.user;
        if (user) {
          var policyRequest = new B2CApiServiceModels.Module.User.UserPolicyListRequest();
          policyRequest.token = user?.token;
          let policyResponse = await B2CApiServices.UserService.AuthService.UserPolicyList(policyRequest);
          if (policyResponse.header.success) {
            var siteMappings = user.userMappings;
            user.policies = policyResponse.body?.policies;
            var baseSite = _.find(siteMappings, function (s) { return s.webSite?.isBaseSite });
            appDispatch(setUser(user));
            if (baseSite == null)
              dispatch(getSiteCulture());
            dispatch(toUnloading());
            navigate('/');
          }
        }
        else {
          dispatch(toUnloading());
          toast.error("Login failed : User is empty");
        }
      }
      else {
        dispatch(toUnloading());
        toast.error(res.header.messages?.at(0)?.message);
      }
    } catch (e: any) {
      dispatch(toUnloading());
      toast.error("Server Connection Failed");
    }
  }

  const getMainWrapperStyle = () => {
    if (uiInfo?.loginBackground) {
      return {
        backgroundImage: `url(${uiInfo?.loginBackground})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: '100% 100%'
      };
    }
    return {}
  }

  const handleRememberMe = () => {
    setRememberMe(!rememberMe);
  }

  return (
    <>
      <div className={`${uiInfo?.loginBackground ? 'login-customized' : ''}`} style={getMainWrapperStyle()}>
        <div className="login-box">
          <div className={`card card-outline card-primary mode-light`}>
            {
              !uiInfo?.companyLogo
                ?
                <div className="card-header text-center">
                  <span className="h1">
                    <b>B2C</b>
                    <span>Panel</span>
                  </span>
                </div>
                :
                <div className='text-center' style={{ marginTop: "10px" }}>
                  <img style={{ maxHeight: "70px", maxWidth: "240px", objectFit: "contain" }}
                    src={uiInfo?.companyLogo}></img>
                </div>
            }
            <div className="card-body">
              <Form autoComplete='off'>
                <div className="mb-3">
                  <Form.Control
                    autoComplete="off"
                    name="mail"
                    type="email"
                    value={loginRequest.mail}
                    onChange={(e) => setLoginRequest({ ...loginRequest, ["mail"]: e })}
                    className='form-control login-user'
                    placeholder={t("login.email")}
                  />
                </div>
                <div className="password mb-3">
                  <Form.Control
                    autoComplete="off"
                    className='form-control login-user'
                    name="password"
                    value={loginRequest.password}
                    onChange={(e) => setLoginRequest({ ...loginRequest, ["password"]: e })}
                    type={visible ? 'text' : 'password'}
                    placeholder={t("login.password")}
                  />
                  <div className="input-group-append eye-icon">
                    <div className="input-group-text" style={{ backgroundColor: "transparent", border: "none" }}>
                      {visible ?
                        <span onClick={handlePassChange} className="fas fa-eye"></span>
                        :
                        <span onClick={handlePassChange} className="fas fa-eye-slash"></span>
                      }
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-8">
                    <PfCheckbox checked={rememberMe} onClick={() => handleRememberMe()}>
                      {t('login.label.rememberMe')}
                    </PfCheckbox>
                  </div>
                  <div className="col-4">
                    <PfButton
                      onClick={() => handleSubmit()}
                      block
                      itemType='button'
                    >
                      {t('login.button.signIn.label')}
                    </PfButton>
                  </div>
                </div>
              </Form>
              <p className="mb-1">
                <Link to="/forgot-password">
                  {t('login.label.forgotPass')}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Login;
