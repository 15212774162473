import { B2CUiHelpers, B2CApiServiceModels, B2CApiServices, B2CUiModels, B2CUiProps, B2CApiEnums, B2CUiEnums } from "@santsg/ui-component-core";
import { Module, Permission } from "models/enums/moduleAndPermission";
import SanAbility from "components/sanability/SanAbility";
import _ from 'lodash';
import { SanFormControl } from "components/common/SanFormControl";
import { Button, ButtonToolbar, Form } from "rsuite";
import { useTranslation } from "react-i18next";
import { AccepterType } from "models/enums/FormAccepterTypes";
import React, { Fragment, useEffect, useState } from "react";
import { SanColumnItem } from "models/types/san-elements/SanColumnItem";
import { SanActionWrapper } from "models/types/san-elements/SanActionWrapper";
import { enmActionCellType } from "models/enums/ActionCellType";
import { enmButtonAppearance } from "models/enums/ButtonAppearance";
import { enmButtonColor } from "models/enums/ButtonColor";
import SanTable from "components/common/SanTable";
import { ObjectValueController } from "@santsg/ui-common";
import SanModal from 'components/common/SanModal';
import { LogDetail } from "./LogDetail";
import { toast } from 'react-toastify';
import { enmModalSize } from "models/enums/ModalSize";
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ValidationResult, getValidationResult } from "models/types/common/ValidationResult";
import { SanDynamicObject } from "models/types/san-elements/SanDynamicObject";
import { LogFormProps } from "models/types/log/LogProps";
import moment from "moment";
import { toLoading, toUnloading } from "store/features/ui/UiSlice";
import { hasAuth } from "utils/AuthHelper";

function LogList(props: LogFormProps): JSX.Element {
    const [listResponse, setListResponse] = useState<B2CApiServiceModels.Module.Log.LogResponse>();
    const [userListResponse, setUserListResponse] = useState<B2CApiServiceModels.Module.User.UserListResponse>();
    const [logDetail, setLogDetail] = useState<B2CApiServiceModels.Module.Log.LogDetail>();
    const [openLogDetailForm, setOpenLogDetailForm] = useState(false);
    const [request, setRequest] = React.useState<B2CApiServiceModels.Module.Log.LogRequest>({});
    const [t] = useTranslation();
    const [validationErrors, setValidationErrors] = useState<Array<ValidationResult>>([]);
    const [webSiteListCodeValue, setWebSiteListCodeValue] = useState<Array<B2CUiModels.mdlIdNameCodeValue>>();
    const [userListCodeValue, setuserListCodeValue] = useState<Array<B2CUiModels.mdlIdNameCodeValue>>();
    const authUi = useAppSelector(s => s.auth);
    const dispatch = useAppDispatch();
    const settings = useAppSelector(state => state.settings);

    useEffect(() => {
        if (!listResponse) {
            getUsers();
            prepareRequest();
        }
    }, [listResponse]);


    const prepareRequest = () => {
        var logDetailRequested = false;
        var contentFilterApplied = false;
        if (request.id == undefined && props.log)
            logDetailRequested = true;
        if (props.contentType)
            contentFilterApplied = true;

        var pListRequest = new B2CApiServiceModels.Module.Log.LogRequest(
            props.log?.contentType as B2CApiEnums.enmLogContentType
        );

        if (logDetailRequested) {
            pListRequest.objectId = (props.log?.objectId ?? B2CUiHelpers.ObjectValueHelper.generateEmptyGuid());
            pListRequest.id = props.log?.id;
            pListRequest.index = props.log?.index;
            pListRequest.skip = props.log?.skip;
            pListRequest.take = props.log?.take;
            pListRequest.contentType = props.log?.contentType;
            pListRequest.types = props.log?.types;
            pListRequest.userIds = props.log?.userIds;
            pListRequest.objectId = props.log?.objectId;
            pListRequest.siteIds = props.log?.siteIds;
            pListRequest.startDate = props.log?.startDate;
            pListRequest.endDate = props.log?.endDate;
        }
        else if (contentFilterApplied) {
            pListRequest.contentType = props.contentType;
            pListRequest.startDate = moment().startOf('day').toDate();
            pListRequest.endDate = moment().endOf('day').toDate();
            pListRequest.types = props.types;
        }
        else {
            pListRequest.contentType = B2CApiEnums.enmLogContentType.All;
            pListRequest.types = [B2CApiEnums.enmLogType.Insert, B2CApiEnums.enmLogType.Update, B2CApiEnums.enmLogType.Delete];
        }

        setRequest(pListRequest);
        getWebSites();
        getLogs(pListRequest);
    }

    //#region HELPERS
    const checkValidation = () => {
        var messages = new Array<ValidationResult>();
        var result = false;
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.skip)) {
            messages.push(getValidationResult(t, "skip"));
        }

        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.take)) {
            messages.push(getValidationResult(t, "take"));
        }

        messages.length == 0 ? result = true : result = false;
        setValidationErrors(messages);
        return result;
    }

    //#endregion
    //#region API CALLS
    async function getLogs(request?: B2CApiServiceModels.Module.Log.LogRequest) {
        if (request == null) {
            return;
        }
        let _types: Array<Number> = new Array<Number>;
        let req = _.cloneDeep(request) as SanDynamicObject;
        request.types?.forEach(type => {
            _types.push(Number(type));
        });
        req.types = _types;
        if (ObjectValueController.isNullOrUndefinedOrEmpty(req.objectId)) {
            req.objectId = B2CUiHelpers.ObjectValueHelper.generateEmptyGuid();
        }
        var response = await B2CApiServices.LogService.LogService.List(req, false, true);
        if (response && response.header.success && response.body) {
            setListResponse(response.body);
        }
        else {
            setListResponse(new B2CApiServiceModels.Module.Log.LogResponse([]));
        }
    }
    async function getWebSites() {
        let _list = new Array<B2CUiModels.mdlIdNameCodeValue>();
        _.each(authUi?.user?.userMappings, function (x) {
            if (x.webSite != null) {

                var item = new B2CUiModels.mdlIdNameCodeValue();
                item.code = x.webSite.id;
                item.name = x.webSite.name;
                item.id = x.webSite.id;
                item.value = x.webSite.id;
                _list.push(item);
            }
        });
        setWebSiteListCodeValue(_list);
    }
    async function getUsers() {
        let _list = new Array<B2CUiModels.mdlIdNameCodeValue>();
        var userListRequest = new B2CApiServiceModels.Module.User.UserListRequest();
        userListRequest.userType = B2CApiEnums.enmUserType.Panel;
        userListRequest.pageSize = 0;
        var response = await B2CApiServices.UserService.AppUserService.List(userListRequest, false, true);
        if (response && response.header.success && response.body) {
            setUserListResponse(response.body);
            response.body?.data?.map(x => {
                var item = new B2CUiModels.mdlIdNameCodeValue();
                item.code = x.id;
                item.name = x.mail;
                item.id = x.id;
                item.value = x.id;
                _list.push(item);
            });
            setuserListCodeValue(_list);
        }
        else {
            setUserListResponse(new B2CApiServiceModels.Module.User.UserListResponse([]));
        }
        logDataModify();
    }
    //#endregion API CALLS

    //#region functions
    function logDataModify() {
        listResponse?.data?.forEach(item => {
            if (item.userID != null) {
                item.userExplanation = getUserMailAddress(item.userID);
            }
        });
    }
    function getUserMailAddress(userId: string) {
        const user = _.find(userListResponse?.data, function (c) { return c.id == userId });
        if (user) {
            return user.mail;
        }
        return "Undefined";
    }
    //#endregion functions

    //#region EVENTS
    const handleSubmit = () => {
        setValidationErrors([]);
        if (checkValidation()) {
            getLogs(request);
        }
    }

    const handleShowClick = async (id: string) => {
        const logDetail = _.find(listResponse?.data, function (source) { return source.id == id.toString() });
        if (logDetail != null) {
            setLogDetail(new B2CApiServiceModels.Module.Log.LogDetail(logDetail));
            setOpenLogDetailForm(true);
        }
    };

    const fnHandleModalClose = (operationCompleted?: boolean) => {
        setOpenLogDetailForm(false);
    };
    const fnErrorCallback = (message: string) => toast.error(message);

    const logFormProps: LogFormProps = {
        errorCallback: fnErrorCallback,
        successCallback: fnHandleModalClose,
        detail: logDetail,
        log: request
    };
    //#endregion

    //#region COMPONENT PROPS
    const tableColumns: SanColumnItem[] = [
        {
            key: "id",
            type: "string",
            label: "logPage.logList.Id",
            visible: false
        },
        {
            key: "userExplanation",
            type: "string",
            label: t("logPage.logList.user"),
            visible: true
        },
        {
            key: "contentTypeExplanation",
            type: "string",
            label: t("logPage.logList.contentType"),
            visible: true
        },
        {
            key: "typeExplanation",
            type: "string",
            label: t("logPage.logList.type"),
            visible: true
        },
        {
            key: "operationTime",
            type: Date,
            label: t("logPage.logList.operationTime"),
            visible: true
        }
    ];

    const tableActionWrapper: SanActionWrapper = {
        actionCellType: enmActionCellType.Row,
        actions: [
            {
                apperance: enmButtonAppearance.ButtonPrimary,
                color: enmButtonColor.Blue,
                label: t("common.show"),
                onClick: handleShowClick,
                visible: hasAuth(B2CApiEnums.enmModule.Log, B2CApiEnums.enmPermission.Read, authUi?.authorizedModules)
            }]
    };

    //#endregion 
    return (
        <Fragment>
            <Form>
                <div className="row mb-4">
                    <div className="col-sm-3">
                        <SanFormControl
                            model={request}
                            onChange={setRequest}
                            name="index"
                            type="text"
                            label={t("logPage.logForm.index")}
                            placeholder={t("logPage.logForm.index")}
                            helperText={t("logPage.logForm.indexInputInfo")}
                            errors={validationErrors}
                        />
                    </div>
                    <div className="col-sm-3">
                        <SanFormControl
                            model={request}
                            onChange={setRequest}
                            name="objectId"
                            type="text"
                            label={t("logPage.logForm.objectId")}
                            placeholder={t("logPage.logForm.objectId")}
                            helperText={t("logPage.logForm.objectIdInputInfo")}
                            errors={validationErrors}
                        />
                    </div>
                    <div className="col-sm-3">
                        <SanFormControl
                            model={request}
                            onChange={setRequest}
                            dataSource={B2CUiHelpers.EnumHelper.getEnumKeyValuesDefault(B2CApiEnums.enmLogContentType, true)}
                            name={"contentType"}
                            accepter={AccepterType.DROPDOWN}
                            label={t("logPage.logForm.contentType")}
                            placeholder={t("logPage.logForm.contentType")}
                            helperText={t("logPage.logForm.contentTypeInputInfo")}
                            errors={validationErrors}
                        />
                    </div>
                    <div className="col-sm-3">
                        <SanFormControl
                            model={request}
                            onChange={setRequest}
                            dataSource={B2CUiHelpers.EnumHelper.getEnumKeyValuesDefault(B2CApiEnums.enmLogType, true)}
                            name={"types"}
                            accepter={AccepterType.CHECKBOXPICKER}
                            label={t("logPage.logForm.type")}
                            placeholder={t("logPage.logForm.type")}
                            helperText={t("logPage.logForm.typeInputInfo")}
                            errors={validationErrors}
                        />
                    </div>
                    <div className="col-sm-3">
                        <SanFormControl
                            model={request}
                            onChange={setRequest}
                            dataSource={userListCodeValue}
                            name={"userIds"}
                            accepter={AccepterType.CHECKBOXPICKER}
                            label={t("logPage.logForm.user")}
                            placeholder={t("logPage.logForm.user")}
                            helperText={t("logPage.logForm.userInputInfo")}
                            errors={validationErrors}
                        />
                    </div>
                    <div className="col-sm-3">
                        <SanFormControl
                            model={request}
                            onChange={setRequest}
                            dataSource={webSiteListCodeValue}
                            name={"siteIds"}
                            accepter={AccepterType.CHECKBOXPICKER}
                            label={t("logPage.logForm.webSites")}
                            placeholder={t("logPage.logForm.webSites")}
                            helperText={t("logPage.logForm.webSitesInputInfo")}
                            errors={validationErrors}
                        />
                    </div>
                    <div className="col-sm-3">
                        <SanFormControl
                            model={request}
                            onChange={setRequest}
                            accepter={AccepterType.NUMBERBOX}
                            name="skip"
                            label={t("logPage.logForm.skip") + "*"}
                            errors={validationErrors}
                        />
                    </div>
                    <div className="col-sm-3">
                        <SanFormControl
                            model={request}
                            onChange={setRequest}
                            accepter={AccepterType.NUMBERBOX}
                            name="take"
                            label={t("logPage.logForm.take") + "*"}
                            errors={validationErrors}
                        />
                    </div>
                    <div className="col-sm-3">
                        <SanFormControl
                            model={request}
                            onChange={setRequest}
                            name="startDate"
                            receiveFormat={"YYYY-MM-DD"}
                            accepter={AccepterType.DATEPICKER}
                            format={"dd.MM.yyyy"}
                            label={t("logPage.logForm.startDate")}
                            placeholder={t("logPage.logForm.startDate")}
                            helperText={t("logPage.logForm.startDateInputInfo")}
                            errors={validationErrors}
                        />
                    </div>
                    <div className="col-sm-3">
                        <SanFormControl
                            model={request}
                            onChange={setRequest}
                            name="endDate"
                            receiveFormat={"YYYY-MM-DD"}
                            accepter={AccepterType.DATEPICKER}
                            format={"dd.MM.yyyy"}
                            label={t("logPage.logForm.endDate")}
                            placeholder={t("logPage.logForm.endDate")}
                            helperText={t("logPage.logForm.endDateInputInfo")}
                            errors={validationErrors}
                        />
                    </div>
                    <div className="col-sm-6 mt-4 pr-5">
                        <ButtonToolbar>
                            <Button appearance="primary" onClick={handleSubmit} className="w-100">
                                {t("common.search")}
                            </Button>
                        </ButtonToolbar>
                    </div>
                </div>

            </Form>
            <SanAbility module={Module.Log} permissions={[Permission.Read]} errorState='ShowMessage'>
                {
                    <>
                        <div className="log-list">
                            <div >
                                <SanTable data={listResponse?.data} tableColumns={tableColumns} tableActions={tableActionWrapper} />
                            </div>
                            <SanModal
                                backdrop={true}
                                type={settings?.panel.modalShowType}
                                open={openLogDetailForm}
                                onClose={fnHandleModalClose}
                                size={enmModalSize.FULL}
                                title={t("logPage.logList.logTitle")}
                                body={LogDetail}
                                bodyProps={logFormProps}
                            />
                        </div>
                    </>
                }
            </SanAbility>
        </Fragment>

    )
}
export { LogList };