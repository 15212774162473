// ESLint'in no-unused-vars kuralını devre dışı bırak
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { toggleMode, UiModes } from 'store/features/ui/UiSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { B2CApiEnums, B2CApiServiceModels, B2CApiServices } from '@santsg/ui-component-core';
import _ from 'lodash';
import { SanDropZone } from 'components/common/SanDropZone';
import { enmDropzoneWorkType } from 'models/enums/DropzoneWorkType';
import { Accept } from 'react-dropzone';

const VisualSettings = () => {
    const dispatch = useAppDispatch();
    const mode = useAppSelector(state => state.ui?.mode);
    const authUi = useAppSelector(state => state.auth);

    const [t] = useTranslation();
    const [globalFileResponse, setGlobalFileResponse] = useState<B2CApiServiceModels.Module.AppFile.AppFileGlobalDetailResponse | undefined>();

    useEffect(() => {
        if (!globalFileResponse) getGlobalStyles();
    }, []);

    const getGlobalStyles = async () => {
        const request = new B2CApiServiceModels.Module.AppFile.AppFileGlobalDetailRequest();
        request.fileTypes = [
            B2CApiEnums.enmGlobalFileType.COMPANY_LOGO,
            B2CApiEnums.enmGlobalFileType.LOGIN_BACKGROUND,
            B2CApiEnums.enmGlobalFileType.LOADING
        ];

        if (authUi?.webSite && !authUi.webSite.isBaseSite) {
            request.fileTypes.push(B2CApiEnums.enmGlobalFileType.CMS_LOGO);
        }

        const response = await B2CApiServices.AppFileService.GlobalDetail(request, false, true);
        if (response?.header.success && response.body) {
            setGlobalFileResponse(response.body);
        }
    };

    const handleModeChange = () => {
        dispatch(toggleMode(mode === UiModes.DARK ? UiModes.LIGHT : UiModes.DARK));
    };

    const handleFileChange = async (fileList: File[] | undefined, fileType: B2CApiEnums.enmGlobalFileType) => {
        const fileToUpload = _.first(fileList);
        fileToUpload ? await uploadFile(fileToUpload, fileType) : await removeFile(fileType);
    };

    const uploadFile = async (file: File, fileType: B2CApiEnums.enmGlobalFileType) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileType', fileType.toString());

        const response = await B2CApiServices.AppFileService.UploadGlobal(formData, false, true);
        if (response?.header.success && response.body?.succeed) {
            toast.success(t('common.success'))
            getGlobalStyles();
        }
        else toast.error(response.body?.messages?.join(','));
    };

    const removeFile = async (fileType: B2CApiEnums.enmGlobalFileType) => {
        const deleteRequest = new B2CApiServiceModels.Module.AppFile.AppFileGlobalDeleteRequest(fileType);
        const deleteResponse = await B2CApiServices.AppFileService.GlobalDelete(deleteRequest, false, true);
        if (deleteResponse?.header.success && deleteResponse.body?.deleted) {
            toast.success(t('common.success'));
            getGlobalStyles();
        } else {
            toast.error(deleteResponse.header.messages?.join(','));
        }
    };

    const renderDropZone = (label: string, fileType: B2CApiEnums.enmGlobalFileType, value: any, accept?: Accept, zoneInfo?: JSX.Element) => {
        return (
            <SanDropZone
                label={label}
                workType={enmDropzoneWorkType.Single}
                value={value}
                zoneInfo={zoneInfo}
                accept={accept}
                onChange={(fileList: File[]) => handleFileChange(fileList, fileType)}
                multiple={false}
            />
        );

    }

    const getCompanyLogoSection = () => {
        const compLogo = _.find(globalFileResponse?.appFiles, f => f.fileType === B2CApiEnums.enmGlobalFileType.COMPANY_LOGO);
        return renderDropZone(t('common.companyLogo'), B2CApiEnums.enmGlobalFileType.COMPANY_LOGO, compLogo?.appFile, {
            "image/png": []
        }, <div><div>{t("sandropzone.companyLogo.format")}</div><div>{t("sandropzone.companyLogo.maxWidth")}</div><div>{t("sandropzone.companyLogo.maxHeight")}</div></div>);

    };

    const getLoginBgSection = () => {
        const loginBg = _.find(globalFileResponse?.appFiles, f => f.fileType === B2CApiEnums.enmGlobalFileType.LOGIN_BACKGROUND);
        return renderDropZone(t('common.loginBackground'), B2CApiEnums.enmGlobalFileType.LOGIN_BACKGROUND, loginBg?.appFile, {
            "image/png": [],
            "image/jpeg": [],
            "image/webp": []
        }, <div><div>{t("sandropzone.loginBackground.format")}</div><div>{t("sandropzone.loginBackground.maxWidth")}</div><div>{t("sandropzone.loginBackground.maxHeight")}</div></div>);
    };
    const getLoadingSection = () => {
        const loading = _.find(globalFileResponse?.appFiles, f => f.fileType === B2CApiEnums.enmGlobalFileType.LOADING);
        return renderDropZone(t('common.loading'), B2CApiEnums.enmGlobalFileType.LOADING, loading?.appFile, {
            "image/gif": []
        }, <div><div>{t("sandropzone.loading.format")}</div><div>{t("sandropzone.loading.maxWidth")}</div><div>{t("sandropzone.loading.maxHeight")}</div></div>);

    };

    const getCmsLogoSection = () => {
        if (!authUi?.webSite || authUi.webSite.isBaseSite) return null;
        const cmsLogo = _.find(globalFileResponse?.appFiles, f => f.fileType === B2CApiEnums.enmGlobalFileType.CMS_LOGO);
        return renderDropZone(t('common.cmsLogo'), B2CApiEnums.enmGlobalFileType.CMS_LOGO, cmsLogo?.appFile, {
            "image/png": []
        }, <div><div>{t("sandropzone.cmsLogo.format")}</div><div>{t("sandropzone.cmsLogo.maxWidth")}</div><div>{t("sandropzone.cmsLogo.maxHeight")}</div></div>);

    };

    return (
        <div className='style-settings-wrapper'>
            <legend className="col-form-label pt-0">{t('common.darkMode')}</legend>
            <div className="form-check form-switch" style={{ marginLeft: '30px' }}>
                <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckChecked"
                    onChange={handleModeChange}
                    checked={mode === UiModes.DARK}
                />
            </div>
            <hr />
            <div>
                <div className="d-flex flex-row">
                    {getCompanyLogoSection()}
                    {getLoginBgSection()}
                </div>
                <div className="d-flex flex-row mt-2">
                    {getCmsLogoSection()}
                    {getLoadingSection()}
                </div>
            </div>
        </div>
    );
};

export default VisualSettings;
