import { useEffect, useState } from 'react';
import { PfDropdown } from '@profabric/react-components';
import styled from 'styled-components';
import { B2CApiModels, B2CApiServiceModels, B2CApiServices } from '@santsg/ui-component-core';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { removeSession, setActiveWebSite, setUser } from 'store/features/user/UserSlice';
import _ from 'lodash';
import { CookieManager } from '@santsg/ui-common';
import { UiModes } from 'store/features/ui/UiSlice';

export const StyledDropdown = styled(PfDropdown)`
  border: none;
  width: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  --pf-dropdown-menu-min-width: 10rem;

  .dropdown-item {
    padding: 0.5rem 1rem;
  }

  .text-sm {
    margin-bottom: 0;
  }
  .dropdown-divider {
    margin: 0;
  }
`;


const WebSitesDropdown = () => {
    const dispatch = useAppDispatch();
    const authUi = useAppSelector(s => s.auth);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [webSites, setWebSites] = useState<B2CApiModels.Module.WebSite.mdlWebSite[]>();
    const [selectedSite, setSelectedSite] = useState<B2CApiModels.Module.WebSite.mdlWebSite>();
    const uiInfo = useAppSelector(s => s.ui);
    useEffect(() => {
        if (authUi && authUi.webSite) {
            setSelectedSite(authUi.webSite);
        }
        if (!webSites)
            getWebSites();
    }, [authUi?.mappings]);

    async function getWebSites() {
        var authorizedWebSiteList = new Array<B2CApiModels.Module.WebSite.mdlWebSite>();
        _.each(authUi?.mappings, function (f) {
            if (f.webSite)
                authorizedWebSiteList.push(f.webSite);
        });
        setWebSites(_.uniqBy(authorizedWebSiteList, "id"));
    }

    const changeWebSite = async (webSite: B2CApiModels.Module.WebSite.mdlWebSite) => {
        let req = new B2CApiServiceModels.Module.User.ChangeSiteRequest();
        req.refreshToken = CookieManager.get("refreshToken");
        req.token = CookieManager.get("applicationTokenPanel");
        req.webSiteId = webSite.id;
        var response = await B2CApiServices.UserService.AuthService.ChangeSite(req, false, true);
        if (response && response.header.success && response.body) {
            dispatch(removeSession(undefined));
            dispatch(setActiveWebSite(webSite));
            dispatch(setUser(response.body.user));
            window.location.href = "/";
        }
    }


    return (
        <StyledDropdown isOpen={dropdownOpen} hideArrow>
            <div className="nav-link" slot="button" style={{ display: "flex", flexDirection: "row", gap: "5px", alignItems: "center" }}>
                <i className="fas fa-globe" />
                <span>
                    {selectedSite?.name}
                </span>
            </div>
            <div slot="menu" className={`${uiInfo?.mode == UiModes.DARK ? "mode-dark" : "mode-light"}`}>
                {webSites && webSites.map((webSite) => (
                    <button
                        type="button"
                        key={webSite.id}
                        className={`dropdown-item`}
                        onClick={() => {
                            changeWebSite(webSite);
                            setDropdownOpen(false);
                        }}
                    >
                        <span>{webSite.name}</span>
                    </button>
                ))}
            </div>
        </StyledDropdown>
    );
};

export default WebSitesDropdown;
