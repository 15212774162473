import _ from 'lodash';
import { SanLabelProps } from "models/types/san-elements/SanLabelProps";
import { Form } from 'rsuite';

export const SanLabel = (props: SanLabelProps) => {

    return (
        <div className='row'>
            <div className='col-10'>
                <div className="form-control-wrap">
                    <label className="rs-form-control-label">{props.text}</label>
                    {
                        props.helperText
                        &&
                        <div className='col-2' style={{ marginBottom: "10px" }}>
                            <Form.HelpText tooltip>{props.helperText}</Form.HelpText>
                        </div>
                    }
                </div>
            </div>
        </div>

    )
}