import { Fragment, useEffect, useState } from "react";
import { Button, Table, } from 'rsuite';
import { Cell, Column, HeaderCell } from "rsuite-table";
import _, { isBoolean } from 'lodash';
import moment from 'moment';
import { enmActionCellType } from "models/enums/ActionCellType";
import MoreIcon from '@rsuite/icons/legacy/More';
import { nanoid } from "@reduxjs/toolkit";
import { SanTableProps } from "models/types/san-elements/SanTableProps";
import { SanDynamicObject } from "models/types/san-elements/SanDynamicObject";
import { ActionCellProps } from "models/types/common/ActionCellProps";
import { SanColumnItem } from "models/types/san-elements/SanColumnItem";
import { SanActionItem } from "models/types/san-elements/SanActionItem";
import { enmButtonAppearance } from "models/enums/ButtonAppearance";
import { enmButtonColor } from "models/enums/ButtonColor";
import { useTranslation } from "react-i18next";
import SanModal from "./SanModal";
import { enmModalSize } from "models/enums/ModalSize";
import { LogList } from 'modules/log/LogList';
import { B2CApiEnums, B2CApiServiceModels, B2CUiEnums, B2CUiHelpers, B2CUiModels } from "@santsg/ui-component-core";
import { useAppSelector } from "store/hooks";
import { toast } from 'react-toastify';
import { LogFormProps } from "models/types/log/LogProps";
import { hasAuth } from "utils/AuthHelper";

function SanStandardTable<T extends object>(props: SanTableProps<T>) {
    const [tableData, setTableData] = useState<SanDynamicObject[]>();
    const { t } = useTranslation();
    const [openLogForm, setOpenLogForm] = useState(false);
    const [logItemId, setLogItemId] = useState(B2CUiHelpers.ObjectValueHelper.generateEmptyGuid());
    const settings = useAppSelector(state => state.settings);
    const auth = useAppSelector(state => state.auth);

    useEffect(() => {
        var data = prepareDataColumns();
        if (data)
            setTableData(data);
    }, [props]);

    //#region ELEMENTS
    const CompactCell = (props: any) => <Cell {...props} key={props.index} />;

    const ActionCell = (props: ActionCellProps) => {
        return (
            <Button color={props.action.color} appearance={props.action.apperance} onClick={() => props.action.onClick && props.action.onClick(props.rowData.id)}>
                {props.action.label}
            </Button>
        );
    };

    const ImageCell = (props: any) => (
        <Cell {...props} style={{ padding: 0 }}>
            <div
                style={{
                    width: 60,
                    height: 40,
                    background: '#f5f5f5',
                    borderRadius: 1,
                    marginTop: 2,
                    overflow: 'hidden',
                    display: 'inline-block'
                }}
            >
                <img src={props.rowData[props.dataKey]} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
            </div>
        </Cell>
    );
    //#endregion

    //#region HELPERS
    const prepareDataColumns = (): SanColumnItem[] | undefined => {
        return props.data && (props.data as SanDynamicObject[]).map(obj => {
            const newObj: SanDynamicObject = {};
            for (const key in obj) {
                var tableConfigurationList:SanColumnItem[] = _.filter(props.tableColumns, function (col) {
                    if (col.key?.includes(".")) {
                        return col.key.split(".")[0] == key;
                    }
                    else {
                        return col.key == key;
                    }
                });
                _.each(tableConfigurationList,function(tableConfiguration){
                    var keyHasNested = false;
                    var nestedKey = "";
                    if (tableConfiguration) {
                        var objectCheck = false;
                        if (tableConfiguration.key?.includes(".")) {
                            keyHasNested = true;
                        }

                        if (tableConfiguration.type == "enum") {
                            if (keyHasNested) {
                                nestedKey = tableConfiguration.key!.split(".")[1];
                                objectCheck = Object.values(tableConfiguration?.model).includes(obj[key][nestedKey!]);
                            }else{
                                objectCheck = Object.values(tableConfiguration?.model).includes(obj[key]);
                            }
                        }
                        else {
                            if (keyHasNested)
                                nestedKey = tableConfiguration.key!.split(".")[1];
                            objectCheck = true;
                            nestedKey = tableConfiguration.key!.split(".")[1];
                        }

                        if (tableConfiguration.type != Date && objectCheck) {
                            var value = "";
                            if (keyHasNested) {
                                if (tableConfiguration.type == 'enum') {
                                    value = tableConfiguration.model[obj[key][nestedKey]];
                                    newObj[`${key}.${nestedKey}`] = value;
                                }
                                else {
                                    value = obj[key][nestedKey];
                                    newObj[`${key}.${nestedKey}`] = value;
                                }
                            } else {
                                if (tableConfiguration.type == "enum") {
                                    value = tableConfiguration.model[obj[key]];
                                    newObj[key] = value.replace(/([a-z])([A-Z])/g, '$1 $2');
                                } else if (tableConfiguration.type == Boolean) {
                                    value = obj[key];
                                    if(!isBoolean(value)){
                                        newObj[key] = Number(value) == 1 ?  "✔" : "-";
                                    }else{
                                        newObj[key] = value ? "✔" : "-";
                                    }
                                }
                                else {
                                    value = obj[key];
                                    newObj[key] = value;
                                }
                            }

                        } else if (tableConfiguration.type == Date)
                            newObj[key] = moment(obj[key]).format("DD.MM.YYYY HH:mm");
                        else
                            newObj[key] = obj[key];
                    }
                })
            }
            return newObj;
        });
    }
    //#endregion

    const handleLogClick = (id: string) => {
        if (id) {
            setLogItemId(id);
        }
        setOpenLogForm(true);
    };
    const fnHandleModalClose = (operationCompleted?: boolean) => {
        setOpenLogForm(false);
    };
    const fnErrorCallback = (message: string) => toast.error(message);

    const logIsVisible = () => {
        return hasAuth(B2CApiEnums.enmModule.Log, B2CApiEnums.enmPermission.Read, auth?.authorizedModules)
    }

    return (
        <Fragment key={nanoid()}>
            <Table data={tableData} autoHeight
                bordered
                cellBordered>
                {
                    props.tableColumns && props.tableColumns.map((data: SanColumnItem, index: number) => (
                        
                        <Fragment key={index}>
                            {
                                data && data.visible &&
                                <Column flexGrow={props.flexGrow ?? 1} fullText align="center">
                                    <HeaderCell>{data.label ? data.label : data.key}</HeaderCell>
                                    {
                                        data.type == "image"
                                            ?
                                            <ImageCell dataKey={data.key} />
                                            :
                                            <CompactCell dataKey={data.key} />
                                    }
                                </Column>
                            }
                        </Fragment>
                    ))
                }

                {
                    props.tableActions && props.tableActions.actionCellType == enmActionCellType.Row && (
                        <Column flexGrow={props.tableColumns?.length! > 3 ? 2 : 1} fixed>
                            <HeaderCell>
                                <MoreIcon />
                            </HeaderCell>

                            <Cell style={{ display: "flex", alignItems: "center" }} className="custom-cell-action" dataKey="id">
                                {(rowData: any) => (
                                    <>
                                        {props.tableActions?.actions?.map((action: SanActionItem, index: number) => (
                                            (action.visible && (action.conditions == null || action.conditions(rowData.id))) ? (
                                                <div className="custom-cell" key={nanoid()}>
                                                    <ActionCell rowData={rowData} action={action} />
                                                </div>
                                            ) : null
                                        ))}
                                        {props.logContentType != null && logIsVisible() && (
                                            <div className="custom-cell">
                                                <ActionCell
                                                    rowData={rowData}
                                                    action={{
                                                        apperance: enmButtonAppearance.ButtonPrimary,
                                                        color: enmButtonColor.Cyan,
                                                        label: t("logPage.buttonText"),
                                                        onClick: handleLogClick,
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </>
                                )}
                            </Cell>
                        </Column>
                    )
                }


            </Table>
            {
                props.logContentType && logItemId != null &&
                <SanModal
                    backdrop={true}
                    type={B2CUiEnums.enmModalShowType.Drawer}
                    open={openLogForm}
                    onClose={fnHandleModalClose}
                    size={enmModalSize.FULL}
                    confirmLabel={t("common.add")}
                    title={t("logPage.logModalTitle")}
                    body={LogList}
                    bodyProps={{
                        errorCallback: fnErrorCallback,
                        successCallback: fnHandleModalClose,
                        log: new B2CApiServiceModels.Module.Log.LogRequest(
                            props.logContentType,
                            (logItemId)
                        )
                    } as LogFormProps}
                />
            }
        </Fragment >

    )
}

export default SanStandardTable;