import React, { useEffect, useState } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useWindowSize } from './hooks/useWindowSize';
import { calculateWindowSize } from './utils/helpers';
import { setWindowSize, toLoading } from 'store/features/ui/UiSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { AppRoutes } from 'routes/AppRoutes';
import Overlay from 'components/main/Overlay';
import { checkRefreshToken, setUser, removeSession } from 'store/features/user/UserSlice';
import { getSiteCulture } from 'store/features/siteCulture/SiteCultureSlice';
import { getSiteSettings } from 'store/features/settings/SettingsSlice';
import { B2CApiServiceModels, B2CApiServices } from '@santsg/ui-component-core';
import _ from 'lodash';
import PublicRoutes from 'routes/PublicRoutes';
import { useTranslation } from "react-i18next";

declare const FB: any;

const App = () => {

  const [t] = useTranslation();
  const windowSize = useWindowSize();
  const screenSize = useAppSelector((state: any) => state.ui);
  const autStore = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch();
  (global as any).ApiUrl = process.env.REACT_APP_API_URL;
  (global as any).MockApiUrl = process.env.REACT_APP_MOCK_API_URL;


  useEffect(() => {
    var publicRoutes = PublicRoutes.map(s => s.path);
    const checkSession = async () => {
      var response: any = await Promise.all([dispatch(checkRefreshToken())]);
      if (response && response.length > 0 && response[0] && response[0].payload && response[0].payload.user) {
        if (response[0].payload.user?.userInfo != null) {
          var policyRequest = new B2CApiServiceModels.Module.User.UserPolicyListRequest();
          policyRequest.token = response[0].payload.user?.token;
          let policyResponse = await B2CApiServices.UserService.AuthService.UserPolicyList(policyRequest);
          if (policyResponse.header.success) {
            var user = _.cloneDeep(response[0].payload.user);
            user.policies = policyResponse.body?.policies;
            dispatch(setUser(user));
            dispatch(getSiteCulture());
            dispatch(getSiteSettings());
          }
        }
        else
          if (!publicRoutes.includes(location.pathname))
            location.href = "/login";
      } else {
        if (location.pathname.toString() != "/" && location.pathname.toString() != "/login") {
          alert(t("app.cookieIsExpired"));
        }
        if (!publicRoutes.includes(location.pathname))
          location.href = "/login";
      }
    };
    checkSession();
  }, [autStore?.requireRefresh]);


  useEffect(() => {
    const size = calculateWindowSize(windowSize.width);
    if (screenSize !== size) {
      dispatch(setWindowSize(size));
    }
  }, [windowSize]);

  return (
    <>
      <Overlay />
      <BrowserRouter>
        <AppRoutes />
        <ToastContainer
          autoClose={3000}
          draggable={false}
          position="top-right"
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnHover
        />
      </BrowserRouter>
    </>

  );
};

export default App;
