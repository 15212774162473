import { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigation } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { allRoutes } from './RouteDefinitions';
import _ from 'lodash';
import { IRouteV2 } from 'interfaces/IRouteV2';
type PrivateModuleProps = {
  children: React.ReactNode;
};

const PrivateRoute = (props: PrivateModuleProps) => {
  const auth = useAppSelector(state => state.auth);
  const [authorizedModule, setAuthorizedModule] = useState(false);
  const { pathname } = useLocation();
  
  const checkAccessbility = () => {
    var menuItemInfos = allRoutes.filter(w => pathname.includes(w.path.replace(":id", "")));
    var menuItemInfo = _.last(menuItemInfos);
    var userAuthorization = auth?.authorizedModules.find(w => w.module == menuItemInfo?.module && menuItemInfo.permissions?.some(s => w.permissions.includes(s)));
    setAuthorizedModule(userAuthorization != null);
  }
  useEffect(() => {
    checkAccessbility();
  }, [pathname, auth?.authorizedModules])
  return auth?.token && authorizedModule ? <>{props.children}</> : !auth?.token ? <Navigate to="/login" /> : <></>;
};

export default PrivateRoute;
