/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useTranslation } from 'react-i18next';
import { B2CApiEnums, B2CApiModels, B2CApiServiceModels, B2CApiServices } from '@santsg/ui-component-core';
import _ from 'lodash';
import { enmCmsSettingsTab } from 'models/enums/CmsSettingsTab';
import { AccepterType } from 'models/enums/FormAccepterTypes';
import { ObjectValueController } from '@santsg/ui-common';
import { SanFormControl } from 'components/common/SanFormControl';
import { enmSettingOptionType } from 'models/enums/SettingOptionType';
import { Button, ButtonToolbar, Col, Form, Row } from 'rsuite';
import { getValidationResult, ValidationResult } from 'models/types/common/ValidationResult';
import { toast } from 'react-toastify';

const PanelSmtpSettings = () => {
    const dispatch = useAppDispatch();
    const mode = useAppSelector(state => state.ui?.mode);

    const [t] = useTranslation();

    const [smtpSettingsResponse, setSmtpSettingsResponse] = useState<B2CApiServiceModels.Module.SmtpSettings.SmtpSettingsGlobalDetailResponse>();
    const [request, setRequest] = useState<B2CApiServiceModels.Module.SmtpSettings.SmtpSettingsUpdateRequest>({});
    const settings = useAppSelector(state => state.settings);
    const [validationErrors, setValidationErrors] = useState<Array<ValidationResult>>([]);

    const authUi = useAppSelector(s => s.auth);

    useEffect(() => {
        if (!smtpSettingsResponse) {
            getSmtpSettings();
        }
    }, [smtpSettingsResponse])


    const getSmtpSettings = async () => {
        var request = new B2CApiServiceModels.Module.SmtpSettings.SmtpSettingsGlobalDetailRequest();
        request.eventType = B2CApiEnums.enmEventType.Global;
        let response = await B2CApiServices.SmtpSettingsService.GlobalDetail(request, false, true);
        if (response && response.body && response.body) {
            setSmtpSettingsResponse(response.body!);
            setRequest(response.body.smtpSettings);
        } else {
            setSmtpSettingsResponse(response.body!);
            var uReq = new B2CApiServiceModels.Module.SmtpSettings.SmtpSettingsUpdateRequest();
            uReq.type = B2CApiEnums.enmEventType.Global;
            setRequest(uReq);
        }
    }

    const handleSubmit = () => {
        setValidationErrors([]);
        if (checkValidation()) {
            save();
        }
    }

    async function save() {
        var response = await B2CApiServices.SmtpSettingsService.Update(request as B2CApiServiceModels.Module.SmtpSettings.SmtpSettingsUpdateRequest, false, true);
        if (response && response.header.success && response.body) {
            toast.success(t("common.success"));
        }
        else {
            toast.error(_.first(response.header.messages)?.message);
        }
    }

    const checkValidation = () => {
        var messages = new Array<ValidationResult>();
        var result = false;
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.type)) {
            messages.push(getValidationResult(t, "type"));
        }
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.useSSL)) {
            messages.push(getValidationResult(t, "useSSL"));
        }
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.smtpAddress)) {
            messages.push(getValidationResult(t, "smtpAddress"));
        }
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.smtpPort)) {
            messages.push(getValidationResult(t, "smtpPort"));
        }
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.smtpFromAddress)) {
            messages.push(getValidationResult(t, "smtpFromAddress"));
        }
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.smtpPassword)) {
            messages.push(getValidationResult(t, "smtpPassword"));
        }
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.smtpRelayPermit)) {
            messages.push(getValidationResult(t, "smtpRelayPermit"));
        }
        if (ObjectValueController.isNullOrUndefinedOrEmpty(request.testToAddress)) {
            messages.push(getValidationResult(t, "testToAddress"));
        }
        if (!ObjectValueController.isNullOrUndefinedOrEmpty(request.smtpRelayPermit) && request.smtpRelayPermit && ObjectValueController.isNullOrUndefinedOrEmpty(request.smtpRelayUser)) {
            messages.push(getValidationResult(t, "smtpRelayUser"));
        }


        messages.length == 0 ? result = true : result = false;
        setValidationErrors(messages);

        return result;
    }



    return (
        <Form
            onChange={setRequest}>

            <div className='row smtp-settings-wrapper'>
                <div className='col-6'>

                    {
                        request.smtpRelayPermit
                        &&
                        <SanFormControl
                            model={request}
                            onChange={setRequest}
                            type="text"
                            name="smtpRelayUser"
                            label={t("smtpSettingsPage.smtpSettingsForm.smtpRelayUser")}
                            placeholder={t("smtpSettingsPage.smtpSettingsForm.smtpRelayUser")}
                            helperText={t("smtpSettingsPage.smtpSettingsForm.smtpRelayUser")}
                            errors={validationErrors}
                        />
                    }
                    <SanFormControl
                        model={request}
                        onChange={setRequest}
                        type="text"
                        name="smtpAddress"
                        label={t("smtpSettingsPage.smtpSettingsForm.smtpAddress")}
                        placeholder={t("smtpSettingsPage.smtpSettingsForm.smtpAddress")}
                        helperText={t("smtpSettingsPage.smtpSettingsForm.smtpAddress")}
                        errors={validationErrors}
                    />
                    <SanFormControl
                        model={request}
                        onChange={setRequest}
                        type="number"
                        name="smtpPort"
                        label={t("smtpSettingsPage.smtpSettingsForm.smtpPort")}
                        placeholder={t("smtpSettingsPage.smtpSettingsForm.smtpPort")}
                        helperText={t("smtpSettingsPage.smtpSettingsForm.smtpPort")}
                        errors={validationErrors}
                    />
                    <SanFormControl
                        model={request}
                        onChange={setRequest}
                        type="text"
                        name="smtpFromAddress"
                        label={t("smtpSettingsPage.smtpSettingsForm.smtpFromAddress")}
                        placeholder={t("smtpSettingsPage.smtpSettingsForm.smtpFromAddress")}
                        helperText={t("smtpSettingsPage.smtpSettingsForm.smtpFromAddress")}
                        errors={validationErrors}
                    />
                    <SanFormControl
                        model={request}
                        onChange={setRequest}
                        type="text"
                        name="smtpPassword"
                        label={t("smtpSettingsPage.smtpSettingsForm.smtpPassword")}
                        placeholder={t("smtpSettingsPage.smtpSettingsForm.smtpPassword")}
                        helperText={t("smtpSettingsPage.smtpSettingsForm.smtpPassword")}
                        errors={validationErrors}
                    />

                    <SanFormControl
                        model={request}
                        onChange={setRequest}
                        type="text"
                        name="testToAddress"
                        label={t("smtpSettingsPage.smtpSettingsForm.testToAddress")}
                        placeholder={t("smtpSettingsPage.smtpSettingsForm.testToAddress")}
                        helperText={t("smtpSettingsPage.smtpSettingsForm.testToAddress")}
                        errors={validationErrors}
                    />
                </div>
                <div className='col-6'>
                    <SanFormControl
                        model={request}
                        onChange={setRequest}
                        name="useSSL"
                        accepter={AccepterType.CHECKBOX}
                        placeholder={t("smtpSettingsPage.smtpSettingsForm.useSSL")}
                        helperText={t("smtpSettingsPage.smtpSettingsForm.useSSL")}
                        errors={validationErrors}
                    />
                    <SanFormControl
                        model={request}
                        onChange={setRequest}
                        accepter={AccepterType.CHECKBOX}
                        name="smtpRelayPermit"
                        placeholder={t("smtpSettingsPage.smtpSettingsForm.smtpRelayPermit")}
                        helperText={t("smtpSettingsPage.smtpSettingsForm.smtpRelayPermit")}
                        errors={validationErrors}
                    />
                </div>

            </div>

            <ButtonToolbar className='justify-content-end save-btn'>
                <Button appearance="primary" onClick={handleSubmit}>
                    {t("common.save")}
                </Button>
            </ButtonToolbar>
        </Form>
    );
};

export default PanelSmtpSettings;
