import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { B2CApiModels, B2CApiServiceModels, B2CApiServices } from '@santsg/ui-component-core';
import { IModulePermission, IUser } from 'interfaces/user';
import { CookieManager, LocalStorage, ObjectValueController } from '@santsg/ui-common';
import _ from 'lodash';

let initialState: IUser = {
    authorizedModules: LocalStorage.get('authmodules', true) != undefined && LocalStorage.get("authmodules", true) != "" ? JSON.parse(LocalStorage.get('authmodules', true)!) as Array<IModulePermission> : new Array<IModulePermission>(),
    user: LocalStorage.get('appuser', true) != undefined && LocalStorage.get("appuser", true) != "" ? JSON.parse(LocalStorage.get('appuser', true)!) as B2CApiModels.Module.UserModel.mdlUser : undefined,
    mappings: LocalStorage.get('mappings', true) != undefined && LocalStorage.get("mappings", true) != "" ? JSON.parse(LocalStorage.get('mappings', true)!) as B2CApiModels.Module.UserModel.mdlUserMapping[] : undefined,
    token: CookieManager.get('applicationTokenPanel') != undefined && CookieManager.get("applicationTokenPanel") != "" ? CookieManager.get('applicationTokenPanel') : undefined,
    webSite: CookieManager.get('webSite') != undefined && CookieManager.get("webSite") != "" ? JSON.parse(CookieManager.get('webSite')!) as B2CApiModels.Module.WebSite.mdlWebSite : undefined,
    requireRefresh: false,
};

export const checkRefreshToken = createAsyncThunk('user/refresh-token', async () => {
    const req = new B2CApiServiceModels.Module.User.UserRefreshTokenRequest();
    let refreshToken = CookieManager.get("refreshToken");
    let token = CookieManager.get("applicationTokenPanel");
    var cookieSite = CookieManager.get('webSite');
    if (!ObjectValueController.isNullOrUndefinedOrEmpty(refreshToken) && !ObjectValueController.isNullOrUndefinedOrEmpty(token)) {
        req.refreshToken = refreshToken;
        req.token = token;
        req.webSiteId = cookieSite != null ? JSON.parse(cookieSite).id : null;
        const res = await B2CApiServices.UserService.AuthService.RefreshToken(req, false, true);
        if (res.header && res.header.success && res.body && res.body.user)
            return { user: res.body?.user, expired: false};
        else
            return { user: null, expired: true };
    } else return { user: null, expired: true };
})

const getUserInfo = (pUserInfo: B2CApiModels.Module.UserModel.mdlUser): IUser | null => {
    let userInfo: IUser;
    if (pUserInfo == null)
        return null;
    userInfo = { authorizedModules: [], user: pUserInfo, token: pUserInfo.token!, mappings: pUserInfo.userMappings };
    if (pUserInfo?.policies) {
        userInfo.authorizedModules = [];//Array<IModulePermission>();

        let allPolicies = Array<{ module: string, permission: string }>()
        pUserInfo?.policies.forEach(f => {
            let policyInfo = f.split('-');
            allPolicies.push({ module: policyInfo[0], permission: policyInfo[1] });
            if (userInfo.authorizedModules.find(w => w.module == policyInfo[0]) == null)
                userInfo.authorizedModules.push({ module: policyInfo[0], permissions: Array<string>() });
        })
        userInfo.authorizedModules.forEach(f => {
            var permissionsForModule = allPolicies.filter(w => w.module == f.module).map(s => s.permission);
            f.permissions = _.uniq(permissionsForModule);
        })
    }
    return userInfo;
}

const UserInfoSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        setUser: (state, action) => {
            if (action.payload != undefined) {
                let userInfo = getUserInfo(action.payload);
                if (userInfo) {
                    var cookieSite = CookieManager.get('webSite');
                    var site = undefined;
                    if (cookieSite != undefined && !ObjectValueController.isNullOrUndefinedOrEmpty(cookieSite)) {
                        var pSite = JSON.parse(cookieSite) as B2CApiModels.Module.WebSite.mdlWebSite;
                        userInfo.webSite = pSite;
                        site = pSite;
                    }
                    else {
                        var userMappings = userInfo.user?.userMappings;
                        var baseSiteMapping = _.find(userMappings, function (u) { return u.webSite?.isBaseSite });
                        if (baseSiteMapping) {
                            userInfo.webSite = (baseSiteMapping as B2CApiModels.Module.UserModel.mdlUserMapping).webSite;
                        }
                        else {
                            var firstSiteMapping = _.first(userMappings);
                            userInfo.webSite = (firstSiteMapping as B2CApiModels.Module.UserModel.mdlUserMapping).webSite;
                        }
                        site = userInfo.webSite;
                    }
                    state = userInfo;
                    LocalStorage.set('mappings', JSON.stringify(userInfo.user?.userMappings), true);
                    LocalStorage.set('authmodules', JSON.stringify(userInfo.authorizedModules), true);
                    LocalStorage.set('appuser', JSON.stringify(userInfo.user), true);
                    CookieManager.set('applicationTokenPanel', action.payload.token!, 0, 12);
                    CookieManager.set('refreshToken', action.payload.refreshToken, 0, 12);
                    CookieManager.set('webSite', JSON.stringify(site), 0, 12);
                }
            }
            return state;
        },
        setActiveWebSite: (state, action) => {
            if (action.payload != undefined) {
                state.webSite = action.payload;
                CookieManager.set('webSite', JSON.stringify(action.payload), 0, 1);
                return state;
            }
        },
        setRequireRefresh: (state, action) => {
            if (action.payload != undefined) {
                state.requireRefresh = action.payload;
                return state;
            }
        },
        removeSession: (state) => {
            state.authorizedModules = [];
            state.user = undefined;
            state.webSite = undefined;
            state.token = '';
            LocalStorage.remove('appuser');
            CookieManager.remove('applicationTokenPanel');
            CookieManager.remove('refreshToken');
            CookieManager.remove('webSite');
            LocalStorage.remove('mappings');
            LocalStorage.remove('authmodules');
            return state;
        }
    },
    extraReducers: builder => {
        builder.addCase(checkRefreshToken.fulfilled, (state, action) => {
            if (action.payload != null) {
                let userInfo = getUserInfo(action.payload.user!);
                if (userInfo) {
                    state = userInfo;
                    LocalStorage.set('appuser', JSON.stringify(state.user), true);
                    LocalStorage.set('mappings', JSON.stringify(state.user?.userMappings), true);
                    CookieManager.set('applicationTokenPanel', action.payload.user!.token!, 0, 12);
                    CookieManager.set('refreshToken', action.payload.user!.refreshToken!, 0, 12);
                    LocalStorage.set('authmodules', JSON.stringify(state.authorizedModules), true);
                }
            }
            return state;
        })
    }
})
export const { setUser, removeSession, setActiveWebSite, setRequireRefresh } = UserInfoSlice.actions;
export const UserInfo = (state: IUser) => state;
export default UserInfoSlice.reducer;