import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { PfButton } from '@profabric/react-components';
import { addBodyClass, removeBodyClass, setWindowClass } from 'utils/helpers';
import { Form, InputGroup } from 'rsuite';
import { B2CApiServices, B2CApiServiceModels, B2CApiEnums } from '@santsg/ui-component-core';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setCompanyLogo, setLoginBackground, toLoading, toUnloading, UiModes } from 'store/features/ui/UiSlice';
import { ObjectValueController } from '@santsg/ui-common';
import Footer from 'components/main/footer/Footer';

const ForgotPassword = () => {
  const [t] = useTranslation();
  const uiInfo = useAppSelector(s => s.ui);
  const dispatch = useAppDispatch();
  const searchPath = location.search;
  const [mailSent, setMailSent] = useState<boolean>(false);
  const [restorePhase, setRestorePhase] = useState<boolean>(false);
  const [restoreDone, setRestoreDone] = useState<boolean>(false);
  const [formValue, setFormValue] = useState<B2CApiServiceModels.Module.User.UserForgotPasswordRequest>();
  const [resetFormValue, setResetFormValue] = useState<B2CApiServiceModels.Module.User.UserResetPasswordRequest>();
  const [visible, setVisible] = useState(false);
  const [resetCode, setResetCode] = useState<string>();
  const navigate = useNavigate();
  const [showPage, setShowPage] = useState<boolean>(false);

  useEffect(() => {
    if (uiInfo?.mode == UiModes.DARK) {
      addBodyClass("rs-theme-dark");
      removeBodyClass("rs-theme-light");
    } else {
      addBodyClass("rs-theme-light");
      removeBodyClass("rs-theme-dark");
    }

    setWindowClass(`hold-transition login-page ${uiInfo?.mode == UiModes.DARK ? "mode-dark" : "mode-light"}`);
    var passResetCode = new URLSearchParams(searchPath).get("pass-reset-code");
    if (passResetCode) {
      checkValidate(passResetCode);
    }
    if (!uiInfo?.loginBackground || !uiInfo.companyLogo)
      getGlobalStyles();
    else
      setShowPage(true);
  }, [])

  const getGlobalStyles = async () => {
    var request = new B2CApiServiceModels.Module.AppFile.AppFileGlobalDetailRequest();
    request.fileTypes = [B2CApiEnums.enmGlobalFileType.COMPANY_LOGO, B2CApiEnums.enmGlobalFileType.LOGIN_BACKGROUND];
    var response = await B2CApiServices.AppFileService.GlobalDetail(request, false, true);
    if (response && response.header.success && response.body?.appFiles) {
      var logo = _.find(response.body.appFiles, function (f) { return f.fileType == B2CApiEnums.enmGlobalFileType.COMPANY_LOGO });
      var loginImage = _.find(response.body.appFiles, function (f) { return f.fileType == B2CApiEnums.enmGlobalFileType.LOGIN_BACKGROUND });
      if (logo && logo.appFile && logo.appFile.imagePath) {
        dispatch(setCompanyLogo(logo.appFile.imagePath));
      }

      if (loginImage && loginImage.appFile && loginImage.appFile.imagePath) {
        dispatch(setLoginBackground(loginImage.appFile.imagePath));
      }
    }
    setShowPage(true);
    dispatch(toUnloading());
  }

  const handleSubmit = async () => {
    dispatch(toLoading());
    let req = formValue as B2CApiServiceModels.Module.User.UserForgotPasswordRequest;
    if (ObjectValueController.isNullOrUndefinedOrEmpty(req.mail)) {
      toast.error(t("recover.mailCanNotBeEmpty"));
      return;
    }
    else {
      let res = await B2CApiServices.UserService.AuthService.ForgotPassword(req);
      if (res.header.success) {
        setMailSent(true);
      }
      else {
        toast.error(_.first(res.header.messages)?.message)
      }
    }
    dispatch(toUnloading());
  }


  const handleRefresh = async () => {
    dispatch(toLoading());
    let req = resetFormValue as B2CApiServiceModels.Module.User.UserResetPasswordRequest;
    req.refCode = resetCode;
    if (ObjectValueController.isNullOrUndefinedOrEmpty(req.password)) {
      toast.error(t("recover.passwordCanNotBeEmpty"));
      return;
    }
    if (ObjectValueController.isNullOrUndefinedOrEmpty(req.passwordConfirmation)) {
      toast.error(t("recover.passwordConfirmationCanNotBeEmpty"));
      return;
    }
    if (req.passwordConfirmation != req.password) {
      toast.error(t("recover.passwordsDoNotMatch"));
      return;
    }

    let res = await B2CApiServices.UserService.AuthService.ResetPassword(req);
    if (res.header.success) {
      setRestoreDone(true);
    }
    else {
      toast.error(_.first(res.header.messages)?.message)
    }
    dispatch(toUnloading());
  }

  const checkValidate = async (activationCode: string) => {
    dispatch(toLoading());
    let req = new B2CApiServiceModels.Module.User.UserActivationCodeValidateRequest();
    req.activationCode = activationCode;
    req.userType = B2CApiEnums.enmUserType.Panel;
    let res = await B2CApiServices.UserService.AuthService.Validate(req);
    if (res.header.success) {
      setResetCode(activationCode);
      setRestorePhase(true);
    }
    else {
      toast.error(t("common.validationCodeFailed"));
      navigate("/login");
    }
    dispatch(toUnloading());
  }

  const getMainWrapperStyle = () => {
    if (uiInfo?.loginBackground) {
      return {
        backgroundImage: `url(${uiInfo?.loginBackground})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: '100% 100%'
      };
    }
    return {}
  }

  const handlePassChange = () => {
    setVisible(!visible);
  };


  return (
    <>
      {
        showPage
        &&
        <div className={`${uiInfo?.loginBackground ? 'login-customized' : ''}`} style={getMainWrapperStyle()}>
          <div className="login-box">
            <div className="card card-outline card-primary mode-light">
              {
                !uiInfo?.companyLogo
                  ?
                  <div className="card-header text-center">
                    <span className="h1">
                      <b>B2C</b>
                      <span>Panel</span>
                    </span>
                  </div>
                  :
                  <div className='text-center' style={{ marginTop: "10px" }}>
                    <img style={{ maxHeight: "70px", maxWidth: "240px", objectFit: "contain" }} src={uiInfo.companyLogo}></img>
                  </div>
              }
              {
                !mailSent
                &&
                !restorePhase
                &&
                <div className="card-body">
                  <p className="login-box-msg">
                    {t('recover.forgotYourPassword')}
                  </p>
                  <Form>
                    <div className="mb-3">
                      <InputGroup className="mb-3">
                        <Form.Control
                          id="email"
                          name="email"
                          value={formValue?.mail}
                          type="email"
                          placeholder={t("login.email")}
                          onChange={(e) => setFormValue({ ...formValue, ["mail"]: e })}
                        />

                      </InputGroup>
                    </div>
                    <div className="row">
                      <div className="col-8">
                        <p className="mt-3 mb-1">
                          <Link to="/login">{t('login.button.signIn.label')}</Link>
                        </p>
                      </div>
                      <div className="col-4">
                        <PfButton type="button" block onClick={() => handleSubmit()}>
                          {/* @ts-ignore */}
                          {t<string>('recover.requestNewPassword')}
                        </PfButton>
                      </div>
                    </div>

                  </Form>
                </div>
              }
              {
                restorePhase && !restoreDone
                &&
                <div className="card-body">
                  <p className="login-box-msg">
                    {t('recover.enterNewPassword')}
                  </p>
                  <Form>
                    <div className="input-group mb-3">

                      <Form.Control
                        className='form-control login-user'
                        name="password"
                        value={resetFormValue?.password}
                        type={visible ? 'text' : 'password'}
                        placeholder="Password"
                        onChange={(e) => setResetFormValue({ ...resetFormValue, ["password"]: e })}
                      />
                    </div>
                    <div className="input-group mb-3">

                      <Form.Control
                        className='form-control login-user'
                        value={resetFormValue?.passwordConfirmation}
                        name="passwordConfirmation"
                        type={visible ? 'text' : 'password'}
                        placeholder="Password Confirmation"
                        onChange={(e) => setResetFormValue({ ...resetFormValue, ["passwordConfirmation"]: e })}
                      />
                      <div className="input-group-append eye-icon">
                        <div className="input-group-text" style={{ backgroundColor: "transparent", border: "none" }}>
                          {visible ?
                            <span onClick={handlePassChange} className="fas fa-eye"></span>
                            :
                            <span onClick={handlePassChange} className="fas fa-eye-slash"></span>
                          }
                        </div>
                      </div>
                    </div>

                  </Form>
                  <div className="row">
                    <div className="col-8">

                    </div>
                    <div className='col-4'>
                      <PfButton type="button" block onClick={() => handleRefresh()}>
                        {/* @ts-ignore */}
                        {t<string>('recover.save')}
                      </PfButton>
                    </div>
                  </div>
                </div>
              }
              {
                mailSent && !restorePhase
                &&
                <div className="card-body">
                  <p className="login-box-msg">
                    {t('recover.forgotPasswordMailSent')}
                  </p>
                  <p className="mt-3 mb-1">
                    <Link to="/login">{t('login.button.signIn.label')}</Link>
                  </p>
                </div>
              }

              {
                restorePhase && restoreDone
                &&
                <div className="card-body">
                  <p className="login-box-msg">
                    {t('recover.success')}
                  </p>
                  <p className="mt-3 mb-1">
                    <Link to="/login">{t('login.button.signIn.label')}</Link>
                  </p>
                </div>
              }
            </div>
          </div>
        </div>
      }
      <Footer />
    </>

  );
};

export default ForgotPassword;