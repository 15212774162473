import { Fragment, useEffect, useState } from "react";
import _ from 'lodash';
import moment from 'moment';
import { nanoid } from "@reduxjs/toolkit";
import { Button } from 'rsuite';
import { SanTableProps } from "models/types/san-elements/SanTableProps";
import { SanDynamicObject } from "models/types/san-elements/SanDynamicObject";
import { SanActionItem } from "models/types/san-elements/SanActionItem";
import { SanColumnItem } from "models/types/san-elements/SanColumnItem";
import { B2CApiServiceModels, B2CUiEnums, B2CUiHelpers } from "@santsg/ui-component-core";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { enmButtonAppearance } from "models/enums/ButtonAppearance";
import { enmButtonColor } from "models/enums/ButtonColor";
import SanModal from "./SanModal";
import { LogList } from "modules/log/LogList";
import { enmModalSize } from "models/enums/ModalSize";
import { toast } from 'react-toastify';
import { LogFormProps } from "models/types/log/LogProps";

function SanTreeTable<T extends object>(props: SanTableProps<T>) {
    const [tableData, setTableData] = useState<SanDynamicObject[]>();
    const [openDatas, setOpenDatas] = useState<number[]>([]);
    const [openLogForm, setOpenLogForm] = useState(false);
    const [logItemId, setLogItemId] = useState(B2CUiHelpers.ObjectValueHelper.generateEmptyGuid());
    const { t } = useTranslation();
    const settings = useAppSelector(state => state.settings);

    useEffect(() => {
        if (props.data) {
            var data = prepareDataColumns();
            if (data)
                setTableData(data);
        }

    }, [props]);

    //#region HELPERS
    const prepareDataColumns = (): SanColumnItem[] | undefined => {
        return props.data && (props.data as SanDynamicObject[]).map(obj => {
            const newObj: SanDynamicObject = {};

            for (const key in obj) {
                var tableConfiguration = _.find(props.tableColumns, function (col) {
                    if (col.key?.includes(".")) {
                        return col.key.split(".")[0] == key;
                    }
                    else {
                        return col.key == key;
                    }
                });
                let keyHasNested = false;
                let nestedKey = "";

                if (tableConfiguration) {
                    if (tableConfiguration.key?.includes(".")) {
                        keyHasNested = true;
                        nestedKey = tableConfiguration.key.split(".")[1];
                    }

                    if (tableConfiguration.type !== Date) {
                        let value = "";

                        if (keyHasNested) {
                            if (tableConfiguration.type === 'enum') {
                                value = tableConfiguration.model[obj[key][nestedKey]];
                                newObj[key] = value;
                            } else {
                                value = obj[key][nestedKey];
                                newObj[key] = value;
                            }
                        } else {
                            if (tableConfiguration.type === "enum") {
                                value = tableConfiguration.model[obj[key]];
                                newObj[key] = value.replace(/([a-z])([A-Z])/g, '$1 $2');
                            } else {
                                value = obj[key];
                                newObj[key] = value;
                            }
                        }
                    } else {
                        newObj[key] = moment(obj[key]).format("YYYY-MM-DD HH:mm:ss");
                    }

                    newObj.configKey = key;

                    // Process child objects if applicable
                    if (props.childSeperatorKey && props.childKey && obj[props.childSeperatorKey]) {
                        const separatedKeyList: SanDynamicObject[] = [];

                        _.each(obj[props.childSeperatorKey], sepItem => {
                            const newChildObj: SanDynamicObject = {};

                            for (const childKey in sepItem) {
                                var foundConfig = _.find(props.childrenColumns, function (col) {
                                    if (col.key?.includes(".")) {
                                        return col.key.split(".")[0] == childKey;
                                    }
                                    else {
                                        return col.key == childKey;
                                    }
                                });
                                let childKeyHasNested = false;
                                let childNestedKey = "";

                                if (foundConfig) {
                                    if (foundConfig.key?.includes(".")) {
                                        childKeyHasNested = true;
                                        childNestedKey = foundConfig.key.split(".")[1];
                                    }

                                    if (foundConfig.type !== Date) {
                                        let childValue = "";

                                        if (childKeyHasNested) {
                                            if (foundConfig.type === 'enum') {
                                                childValue = foundConfig.model[sepItem[childKey][childNestedKey]];
                                                newChildObj[childKey] = childValue;
                                            } else {
                                                childValue = sepItem[childKey][childNestedKey];
                                                newChildObj[childKey] = childValue;
                                            }
                                        } else {
                                            if (foundConfig.type === "enum") {
                                                childValue = foundConfig.model[sepItem[childKey]];
                                                newChildObj[childKey] = childValue.replace(/([a-z])([A-Z])/g, '$1 $2');
                                            } else {
                                                newChildObj[childKey] = sepItem[childKey];
                                            }
                                        }
                                    } else {
                                        newChildObj[childKey] = moment(sepItem[childKey]).format("YYYY-MM-DD HH:mm:ss");
                                    }
                                }
                            }

                            separatedKeyList.push(newChildObj);
                        });

                        newObj[props.childSeperatorKey] = separatedKeyList;
                    }
                }
            }

            return newObj;
        });
    };


    const getCellClass = (columnConfig: SanColumnItem) => {
        if (!columnConfig.visible) {
            return "not-column";
        } else {
            if (props.childTableActions) {
                return "with-action";
            }
        }
    }
    //#endregion

    //#region EVENTS
    const handleCollapseClick = (index: number) => {
        if (openDatas) {
            if (openDatas.includes(index)) {
                setOpenDatas(_.filter(openDatas, function (od) { return od != index }));
            } else {
                var cloned = _.cloneDeep(openDatas);
                cloned.push(index);
                setOpenDatas(cloned);
            }
        }
    }

    const handleActionClick = (data: SanDynamicObject, action: SanActionItem, isParent: boolean) => {
        var value = isParent ? data[props.mainSelector] : data[props.childKey!];
        if (props.mainModel) {
            var trimKey = value.split(' ').join('');
            var foundModel = props.mainModel[trimKey];
            if (foundModel) {
                value = parseInt(foundModel);
            }
        }
        action && action.onClick && action.onClick(value);
    }
    //#endregion

    //#region RENDERERS
    const renderChildCellData = (objectKey: string, data: SanDynamicObject) => {
        var childConfig = _.find(props.childrenColumns, function (cc) {
            if (cc.key?.includes(".")) {
                return cc.key.split(".")[0] == objectKey;
            }
            else {
                return cc.key == objectKey;
            }

        });

        if (childConfig) {
            return (
                <>
                    <td className={`san-table-cell ${getCellClass(childConfig)}`}>
                        <div className="cell-data-wrap">
                            {data[objectKey]}
                        </div>
                        {
                            props.childTableActions
                            &&
                            <div className="head-action-area">
                                {
                                    props.childTableActions?.actions?.map((action: SanActionItem, index: number) => (
                                        <Fragment key={index}>
                                            <Button color={action.color} appearance={action.apperance} onClick={() => handleActionClick(data, action, false)}>
                                                {action.label}
                                            </Button>
                                        </Fragment>
                                    ))
                                }
                            </div>

                        }

                    </td>

                </>
            )
        }
    }
    //#endregion

    const handleLogClick = (id: string) => {
        if (id) {
            setLogItemId(id);
        }
        setOpenLogForm(true);
    };
    const fnHandleModalClose = (operationCompleted?: boolean) => {
        setOpenLogForm(false);
    };
    const fnErrorCallback = (message: string) => toast.error(message);

    return (
        <Fragment key={nanoid()}>
            <table className="table san-table-dark">
                <thead>
                    <tr className={`san-table-row ${props.tableActions ? 'row-with-action' : ''}`}>
                        {
                            props.tableColumns?.map((column: SanColumnItem, index: number) => (
                                <Fragment key={index}>
                                    {
                                        column.visible
                                        &&
                                        <>
                                            <th className={`san-table-cell san-table-header-text ${props.tableActions && props.useCollapse ? 'with-action' : ''}`}>
                                                <span> {column.label}</span>
                                            </th>
                                        </>
                                    }
                                </Fragment>
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        tableData?.map((data: SanDynamicObject, index: number) => (
                            <tr key={index}>
                                {
                                    props.useCollapse
                                        ?
                                        <>
                                            <td className="san-table-cell collapse-area" >
                                                <div className="head-wrapper" >
                                                    <div className="head-text-area" onClick={() => handleCollapseClick(index)}>
                                                        {
                                                            props.tableColumns?.filter(column => column.visible)?.map((column: SanColumnItem, columnIndex: number) => (
                                                                <div className="column-wrapper" key={columnIndex}>
                                                                    {
                                                                        columnIndex == 0
                                                                        &&
                                                                        <span>➥</span>
                                                                    }
                                                                    <span>{data[column.key!]}</span>
                                                                </div>
                                                            ))
                                                        }


                                                    </div>
                                                    <div className="head-action-area">
                                                        {props.tableActions && (
                                                            <>
                                                                {props.tableActions?.actions?.map((action: SanActionItem, index: number) => (
                                                                    <Button
                                                                        style={{ marginRight: 5 }}
                                                                        key={index}
                                                                        color={action.color}
                                                                        appearance={action.apperance}
                                                                        onClick={() => handleActionClick(data, action, true)}
                                                                    >
                                                                        {action.label}
                                                                    </Button>
                                                                ))}
                                                            </>
                                                        )}

                                                        {props.logContentType != null && (
                                                            <Button
                                                                key="log-button"
                                                                color={enmButtonColor.Cyan}
                                                                appearance={enmButtonAppearance.ButtonPrimary}
                                                                onClick={() => handleLogClick(data?.id)}
                                                            >
                                                                {t("logPage.buttonText")}
                                                            </Button>
                                                        )}
                                                    </div>

                                                </div>

                                                {
                                                    openDatas && openDatas.includes(index)
                                                    &&
                                                    <div className="san-table-collapse-area">
                                                        <table className="table san-table-dark">
                                                            <thead>
                                                                <tr>
                                                                    {
                                                                        props.childrenColumns?.map((childColumn: SanColumnItem, childColumnIndex: number) => (
                                                                            <Fragment key={childColumnIndex}>
                                                                                {
                                                                                    childColumn.visible
                                                                                    &&
                                                                                    <th className={`san-table-cell san-table-header-text ${props.childTableActions ? 'with-action' : ''}`}>
                                                                                        <span> {childColumn.label}</span>
                                                                                    </th>
                                                                                }
                                                                            </Fragment>
                                                                        ))
                                                                    }
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    data[props.childSeperatorKey!].map((childObject: SanDynamicObject, childItemIndex: number) => (
                                                                        <tr key={childItemIndex}>
                                                                            {
                                                                                props.childrenColumns?.map((childColumn: SanColumnItem, childColumnIndex: number) => (
                                                                                    <Fragment key={childColumnIndex}>
                                                                                        {renderChildCellData(childColumn.key?.includes(".") ?
                                                                                            childColumn.key.split(".")?.[0]! : childColumn.key!, childObject)}
                                                                                    </Fragment>
                                                                                ))

                                                                            }
                                                                        </tr>
                                                                    ))
                                                                }

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                }
                                            </td>
                                        </>

                                        :
                                        <td className={`san-table-cell  ${props.tableActions ? 'with-action' : ''}`}>
                                            <div className="head-wrapper">
                                                <div className="head-text-area">
                                                    <span>{data[data.configKey]}</span>
                                                </div>
                                                <div className="head-action-area">
                                                    {
                                                        props.tableActions
                                                        &&
                                                        props.tableActions?.actions?.map((action: SanActionItem, index: number) => (
                                                            <Fragment key={index}>
                                                                <Button color={action.color} appearance={action.apperance} onClick={() => handleActionClick(data, action, true)}>
                                                                    {action.label}
                                                                </Button>
                                                            </Fragment>
                                                        ))

                                                    }

                                                </div>
                                            </div>
                                        </td>
                                }
                            </tr>


                        ))
                    }
                </tbody>
            </table>
            {
                props.logContentType &&
                <SanModal
                    backdrop={true}
                    type={B2CUiEnums.enmModalShowType.Drawer}
                    open={openLogForm}
                    onClose={fnHandleModalClose}
                    size={enmModalSize.FULL}
                    confirmLabel={t("common.add")}
                    title={t("logPage.logModalTitle")}
                    body={LogList}
                    bodyProps={{
                        errorCallback: fnErrorCallback,
                        successCallback: fnHandleModalClose,
                        log: new B2CApiServiceModels.Module.Log.LogRequest(
                            props.logContentType,
                            (logItemId)
                        )
                    } as LogFormProps}
                />
            }
        </Fragment>

    )
}

export default SanTreeTable;