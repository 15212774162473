import { createSlice } from '@reduxjs/toolkit';
import { LocalStorage } from '@santsg/ui-common';

import {
  addBodyClass,
  addWindowClass,
  calculateWindowSize,
  removeBodyClass,
  removeWindowClass
} from 'utils/helpers';
import {
  NAVBAR_DARK_VARIANTS,
  NAVBAR_LIGHT_VARIANTS,
  SIDEBAR_DARK_SKINS,
  SIDEBAR_LIGHT_SKINS
} from 'utils/themes';

export interface UiState {
  screenSize: string;
  menuSidebarCollapsed: boolean;
  controlSidebarCollapsed: boolean;
  mode: UiModes;
  headerBorder: boolean;
  headerFixed: boolean;
  footerFixed: boolean;
  layoutBoxed: boolean;
  layoutFixed: boolean;
  menuItemFlat: boolean;
  menuChildIndent: boolean;
  navbarVariant: string;
  sidebarSkin: string;
  triggerPathChange: boolean;
  activePath: string;
  companyLogo: string | undefined;
  cmsLogo: string | undefined;
  loginBackground: string | undefined;
  loading: boolean;
  build: number | undefined;
}

enum modeParams {
  NavbarVariant = 1,
  SidebarSkin = 2
}

export enum UiModes {
  DARK = 1,
  LIGHT = 2
}

const getModeParams = (param: modeParams) => {
  var localValue = LocalStorage.get('ui-mode') != undefined ? LocalStorage.get('ui-mode')?.toString() : UiModes.DARK.toString();
  var parsedValue = localValue == "1" ? UiModes.DARK : UiModes.LIGHT;
  switch (param) {
    case modeParams.NavbarVariant:
      return parsedValue == UiModes.DARK ? NAVBAR_DARK_VARIANTS[0].value : NAVBAR_LIGHT_VARIANTS[0].value;
    case modeParams.SidebarSkin:
      return parsedValue == UiModes.DARK ? SIDEBAR_DARK_SKINS[0].value : SIDEBAR_LIGHT_SKINS[0].value;
  }
}


const getModeAndPrepareUiFromCookie = () => {
  var localValue = LocalStorage.get('ui-mode') != undefined ? LocalStorage.get('ui-mode')?.toString() : UiModes.DARK.toString();
  var parsedValue = localValue == "1" ? UiModes.DARK : UiModes.LIGHT;
  if (parsedValue == UiModes.DARK) {
    addWindowClass('dark-mode');
    addBodyClass("rs-theme-dark");
    removeBodyClass('rs-theme-light');
  } else {
    removeWindowClass('dark-mode');
    removeBodyClass('rs-theme-dark');
    addBodyClass("rs-theme-light");
  }
  return parsedValue;
}

const initialState: UiState = {
  screenSize: calculateWindowSize(1200),
  mode: getModeAndPrepareUiFromCookie(),
  navbarVariant: getModeParams(modeParams.NavbarVariant),
  sidebarSkin: getModeParams(modeParams.SidebarSkin),
  menuSidebarCollapsed: false,
  controlSidebarCollapsed: true,
  headerBorder: false,
  headerFixed: false,
  footerFixed: true,
  layoutBoxed: false,
  menuItemFlat: false,
  menuChildIndent: false,
  layoutFixed: false,
  triggerPathChange: false,
  activePath: '/',
  companyLogo: undefined,
  cmsLogo: undefined,
  loginBackground: undefined,
  loading: false,
  build: undefined
};

//addWindowClass('layout-footer-fixed');

export const uiSlice = createSlice({
  name: 'ui',
  initialState: initialState,
  reducers: {

    toggleMode: (state, action) => {
      state.mode = action.payload;
      if (state.mode == UiModes.DARK) {
        state.navbarVariant = NAVBAR_DARK_VARIANTS[0].value;
        state.sidebarSkin = SIDEBAR_DARK_SKINS[0].value;
      } else {
        state.navbarVariant = NAVBAR_LIGHT_VARIANTS[0].value;
        state.sidebarSkin = SIDEBAR_LIGHT_SKINS[0].value;
      }

      if (state.mode == UiModes.DARK) {
        addWindowClass('dark-mode');
        addBodyClass("rs-theme-dark");
        removeBodyClass('rs-theme-light');
      } else {
        removeWindowClass('dark-mode');
        removeBodyClass('rs-theme-dark');
        addBodyClass("rs-theme-light");
      }
      LocalStorage.set('ui-mode', state.mode);
    },
    triggerPath: (state, { payload }) => {
      state.activePath = payload;
      state.triggerPathChange = true;
    },
    toggleSidebarMenu: (state) => {
      state.menuSidebarCollapsed = !state.menuSidebarCollapsed;
    },
    toggleControlSidebar: (state) => {
      state.controlSidebarCollapsed = !state.controlSidebarCollapsed;
    },
    toggleHeaderBorder: (state) => {
      state.headerBorder = !state.headerBorder;
    },
    toggleHeaderFixed: (state) => {
      state.headerFixed = !state.headerFixed;
      if (state.headerFixed) {
        addWindowClass('layout-navbar-fixed');
      } else {
        removeWindowClass('layout-navbar-fixed');
      }
    },
    toggleFooterFixed: (state) => {
      state.footerFixed = !state.footerFixed;
      if (state.footerFixed) {
        addWindowClass('layout-footer-fixed');
      } else {
        removeWindowClass('layout-footer-fixed');
      }
    },
    toggleLayoutBoxed: (state) => {
      state.layoutBoxed = !state.layoutBoxed;
      if (state.layoutBoxed) {
        addWindowClass('layout-boxed');
      } else {
        removeWindowClass('layout-boxed');
      }
    },
    toggleLayoutFixed: (state) => {
      state.layoutFixed = !state.layoutFixed;
      if (state.layoutFixed) {
        removeWindowClass('layout-fixed');
      } else {
        addWindowClass('layout-fixed');
      }
    },
    toggleMenuItemFlat: (state) => {
      state.menuItemFlat = !state.menuItemFlat;
    },
    toggleMenuChildIndent: (state) => {
      state.menuChildIndent = !state.menuChildIndent;
    },
    setNavbarVariant: (state, { payload }) => {
      if (state.mode == UiModes.DARK) {
        state.navbarVariant = payload || NAVBAR_DARK_VARIANTS[0].value;
      } else {
        state.navbarVariant = payload || NAVBAR_LIGHT_VARIANTS[0].value;
      }
    },
    setSidebarSkin: (state, { payload }) => {
      if (state.mode == UiModes.DARK) {
        state.sidebarSkin = payload || SIDEBAR_DARK_SKINS[0].value;
      } else {
        state.sidebarSkin = payload || SIDEBAR_LIGHT_SKINS[0].value;
      }
    },
    setWindowSize: (state, { payload }) => {
      state.screenSize = payload;
    },
    setCompanyLogo: (state, { payload }) => {
      state.companyLogo = payload;
    },
    toLoading: (state) => {
      state.loading = true;
    },
    setBuildNo: (state, { payload }) => {
      state.build = payload;
    },
    toUnloading: (state) => {
      state.loading = false;
    },
    setCmsLogo: (state, { payload }) => {
      state.cmsLogo = payload;
    },
    setLoginBackground: (state, { payload }) => {
      state.loginBackground = payload;
    },
  }
});

export const {
  toggleSidebarMenu,
  setWindowSize,
  toggleControlSidebar,
  setNavbarVariant,
  setSidebarSkin,
  toggleHeaderBorder,
  toggleHeaderFixed,
  toggleFooterFixed,
  toggleLayoutBoxed,
  toggleMenuItemFlat,
  toggleMenuChildIndent,
  toggleLayoutFixed,
  toggleMode,
  triggerPath,
  setCompanyLogo,
  setCmsLogo,
  setLoginBackground,
  toLoading,
  toUnloading,
  setBuildNo
} = uiSlice.actions;

export default uiSlice.reducer;
